import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomAccordion from "./CustomAccordion";
import { UseFormReturn } from "react-hook-form";

export type PermissionProps = {
  permission: Array<object>,
  control?: any,
  title: string,
  name: string,
  form: UseFormReturn<any>,
  disabled?: boolean,
  permissionDependencies: Map<string, Array<string>>,
}

const handleChange = (props: PermissionProps, handleChange: () => void) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    props.permission.forEach((element: any) => {
      props.form.setValue(element.id, event.target.checked);
    })
    handleChange();
  }
};

const Permission: React.FC<PermissionProps> = (props) => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);    
  const [change, setChange] = useState(true);

  return (
    <CustomAccordion
      accordionProps={{
        expanded,
        onChange: () => { setExpanded(!expanded) }
      }}
      checkboxProps={{
        disabled: props.disabled,
        checked: !!props.permission.find((item: any) => !!props.form.getValues(item.id)),
        onChange: handleChange(props, () => {setChange(!change)}),
        indeterminate: !(props.permission.every((item: any) => 
                            props.form.getValues(item.id) === false 
                         || props.form.getValues(item.id) === undefined) 
                      || props.permission.every((item: any) => 
                            props.form.getValues(item.id) === true))
      }}
      title={t(`role.permission.${props.title}`)}
    >
      <Grid sx={{padding: '0px 20px'}}>
        {
          props.permission.map((item: any) => {
            return (
              <Controller
                key={item.id}
                name={item.id}
                control={props.form.control}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          disabled={props.disabled}
                          color="primary" 
                          checked={!!props.form.getValues(item.id)}
                          onChange={(event) => { 
                            props.form.setValue(item.id, event.target.checked);

                            if (event.target.checked) {
                              props.permissionDependencies.get(item.id)?.forEach(dependencyId => {
                                // if (!props.form.getValues(dependencyId)) {
                                  props.form.setValue(dependencyId, event.target.checked);
                                // }
                              })
                            }

                            setChange(!change);
                          }} 
                        />
                      }
                      label={t(`role.permission.${item.name}`)}
                    />
                  )}
                }
              />
            )
          })
        }
      </Grid>
    </CustomAccordion>
  )
}

export default Permission;