import React from "react";
import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import SubMenuItem from "@/components/menu/SubMenuItem";
import {filterEligibleMenu} from "@/utils/permissions/FilterUnauthed"
import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom';

type MenuProps = {
  title: string;
  menus: any[];
  filterParams?: Record<any, any>,
}

interface NoPermissionObject {
  [key: string]: string;
}


export const titlePermissionObject: NoPermissionObject = {
  '/att/timecard/timecard' : 'common.sidebar.Timecard',
  '/att/timecard/punch-record' : 'common.sidebar.Timecard',
  '/att/report/first-and-last' : 'common.sidebar.Report',
  '/att/report/monthly' : 'common.sidebar.Report',
  '/att/rule/timesheet' : 'common.sidebar.Attendance Rule',
  '/att/schedule' : 'common.sidebar.Attendance Rule',
  '/location/site': 'common.sidebar.Site Management',
  '/location/zone': 'common.sidebar.Site Management',
  '/hr/employee': 'common.sidebar.Organization',
  '/hr/role': 'common.sidebar.Organization',
  '/dms/device': 'dms.Device',
}

export const noPermissionToAccess: NoPermissionObject = {
  '/att/timecard' : "role.You don't have permission to see Timecard details",
  '/att/rule' : "role.You don't have permission to see Attendance Setting details",
  '/hr' : "role.You don't have permission to see Organization details",
  '/location' : "role.You don't have permission to see Site Management details",
  '/att/report' : "role.You don't have permission to see Report details",
  '/nopermission' : "role.You don't have permission to see Dashboard details",
  '/dashboard' : "role.You don't have permission to see Dashboard details",

  '/att/timecard/timecard' : "role.You don't have permission to see Timecard details",
  '/att/timecard/punch-record' : "role.You don't have permission to see Timecard details",
  '/att/report/first-and-last':  "role.You don't have permission to see Report details",
  '/att/report/monthly':  "role.You don't have permission to see Report details",
  '/att/rule/timesheet' : "role.You don't have permission to see Attendance Setting details",
  '/att/rule/schedule' : "role.You don't have permission to see Attendance Setting details",
  '/location/site': "role.You don't have permission to see Site Management details",
  '/location/zone': "role.You don't have permission to see Site Management details",
  '/hr/employee': "role.You don't have permission to see Organization details",
  '/hr/role': "role.You don't have permission to see Organization details",
  '/dms/device': "dms.You don't have permission to see Device details",
  '/att/schedule': "role.You don't have permission to see Attendance Setting details"
}

function NoPermissionText() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const result =  noPermissionToAccess[pathname]

  return (
      <div>
          {result ? (
            <h1>{t(result)}</h1>
          ) :
          (
            <h1>{t("role.No permission to access built-in module")}</h1>
          )}
      </div>
  )
}

const EligibleMenu: React.FC<MenuProps> = (props) => {
  const authedMenus = filterEligibleMenu(props.menus)

  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <Breadcrumbs sx={{height: "32px", p: '8px'}}>
          <Typography>{props.title}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid>
        <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
          {
            authedMenus.length>0? authedMenus.map((item) => <SubMenuItem  key={item.name} {...item} />): <NoPermissionText/>
          }
        </Grid>
      </Grid>
    </Box>
  )
}
export default EligibleMenu
