import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography, Breadcrumbs } from "@mui/material";
import WebURL from "../../../../../urls";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { ATTEndpoints } from "../../../../../services/att/endpoints";
import ScheduleService from "@/services/att/services/ScheduleService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "@/assets/icons/AddIcon.svg";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import GenericService from "../../../../../services/GenericService";
import { HREndpoints } from "../../../../../services/hr/endpoints";
import { EMPTYPES, Department } from "./Sheet.Types";
import TimeSheetSelect from "@/components/scroll-select/TimeSheetSelect";
import AddNewTimeSheet from "./AddNewTimeSheet";
import TransferListLeft from "./TransferListLeft";
import TransferListRight from "./TransferListRight";
import { intersection, not, unintersect, union } from "./utils";
import TransferedClick from "./TransferedClick";

const WAITING_TIME = 900;

const Schedule: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [OutGlobalAutocomplete, SetOutGlobalAutocomplete] = useState<any>({id: "",});
  const [start_date, setStartDate] = React.useState<Date>(new Date());
  const [end_date, setEndDate] = React.useState<Date>(new Date());
  const [overwrite, setOverwrite] = React.useState("yes");
  const [loading, setLoading] = React.useState(false);

  const [timeSheet, setTimeSheet] = React.useState("");

  const [left, setLeft] = React.useState<any[]>([]);
  const [leftSearch, setLeftSearch] = React.useState<any[]>([]);
  const [right, setRight] = React.useState<any[]>([]);
  const [rightSearch, setRightSearch] = React.useState<any[]>([]);

  const [page, setPage] = useState(0);
  const [nameAndId, setNameAndId] = useState("");
  const [nameAndIdRight, setNameAndIdRight] = useState("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentLeftSearch, setDepartmentLeftSearch] = useState("");
  const [departmentRightSearch, setDepartmentRightSearch] = useState("");
  const [isDepartmentLeftSearchEmpty, setIsDepartmentLeftSearchEmpty] = useState(true);
  const [isDepartmentRightSearchEmpty, setIsDepartmentRightSearchEmpty] = useState(true);
  const [autoPages, setAutoPages] = useState<any[]>([])
  const [isFirstDownloaded, setIsFirstDownloaded] = useState<boolean>(true)
  const [isTotalPageSame, setIsTotalPageSame] = useState<boolean>(false)

  const [rightDepartmentValue, setRightDepartmentValue] = useState<Department>({id: "", name: "", code: ""})
  const [leftDepartmentValue, setLeftDepartmentValue] = useState<Department>({id: "", name: "", code: ""})
  const [enableClick, setEnableClick] = useState(0)

  const uniqueArray: any[] = []
  const [numPage, setNumPage] = useState(0)
  const appendData = () => {

    let newPage = 0
    if(page == 0) {
      newPage = page + 1
    } else {
      newPage = page + 1
      if (newPage >= (numPage + 1)) {
        return
      }
    }
    if(uniqueArray.indexOf(newPage) > -1) {
      return
    } else {
      uniqueArray.push(newPage)
    }

    setTimeout(() => {

      GenericService.list(HREndpoints.HR_ACTIVE_EMPLOYEE_URI, { current: newPage, pageSize: 50 })
      .then(({ data }) => {
        setPage(newPage);
        setNumPage(data.num_pages)
        if(right.length > 0) {
          let mergedEmployee: any[] = []
          mergedEmployee = unintersect(data.data, right)
          setLeft(mergedEmployee);
        } else {
          setLeft((prevItems) => [...prevItems, ...data.data]);
        }
        setAutoPages((prevPage) => [...prevPage, newPage])
        if(data.num_pages === newPage) {
          setIsTotalPageSame(true)
        }

      }).catch((error) => {

        if (error.status < 500) {
          // do something when status is 4xx.
          console.log("error " + error);
        }
      })
      setIsFirstDownloaded(false)
    }, isFirstDownloaded ? 1 : WAITING_TIME)

  }

  // Add New Time Sheet
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [newTimeSheet, setNewTimeSheet] = useState("");

  useEffect(() => {
    GenericService.list(HREndpoints.HR_DEPARTMENT_URI, {pageSize: 100}
    ).then(({ data }) => {

      const prevData = [{id: 'All', name: 'All', code: 'All'}];
      const newData = [...prevData, ...data.data]
      setDepartments(newData);
    });
    },
  [])

  useEffect(() => {
    appendData()
  }, [autoPages])

  const [checked, setChecked] = React.useState<any>([]);
  const [checkedRight, setCheckedRight] = React.useState<any>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checkedRight, right);

  const handleToggle = (value: EMPTYPES) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleRight = (value: EMPTYPES) => () => {
    const currentIndex = checkedRight.indexOf(value);
    const newChecked = [...checkedRight];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedRight(newChecked);
  };

  const setRightSearchHandleRight = (data: any[], newDepartmentRightSearch = "") => {

    if(newDepartmentRightSearch.length > 0) {

      if(newDepartmentRightSearch === 'All') {
        if(nameAndIdRight.length > 0) {
          const rightData = [...right, ...data]
          const rightFilterEmployee = rightData?.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });
          setRightSearch(rightFilterEmployee)
        } else {
          setRightSearch([...right, ...data])
        }

      } else {

        if(nameAndIdRight.length > 0) {
          const rightFilterDepartment = right.filter((test) => {
            return (test.department_name === newDepartmentRightSearch)
          })

          const rightFilterEmployee = rightFilterDepartment.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });
          setRightSearch(rightFilterEmployee)

        } else {
          const rightDataResult = [...right, ...data]
          const rightFilterDepartment = rightDataResult.filter((test) => {
            return (test?.department_name.toLowerCase() === newDepartmentRightSearch.toLowerCase())
          })
          setRightSearch(rightFilterDepartment)

        }
      }

    } else {

      if(departmentRightSearch.length > 0) {
        if(departmentRightSearch === 'All') {

          if(nameAndIdRight.length > 0) {
            const rightData = [...right, ...data]
            const rightFilterEmployee = rightData?.filter((test) => {
              return (
                (test.fullName
                  ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                  : "") ||
                test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              );
            });
            setRightSearch(rightFilterEmployee)
          } else {
            setRightSearch([...right, ...data])
          }

        } else {

          if(nameAndIdRight.length > 0) {
            const rightFilterDepartment = right.filter((test) => {
              return (test.department_name === departmentRightSearch)
            })

            const rightFilterEmployee = rightFilterDepartment.filter((test) => {
              return (
                (test.fullName
                  ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                  : "") ||
                test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                //|| test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              );
            });
            setRightSearch(rightFilterEmployee)

          } else {
            const rightDataResult = [...right, ...data]
            const rightFilterDepartment = rightDataResult.filter((test) => {
              return (test?.department_name.toLowerCase() === departmentRightSearch.toLowerCase())
            })
            setRightSearch(rightFilterDepartment)

          }
        }
      }
    }
  }

  const setLeftSearchHandleLeft = (data: any, newDepartmentLeftSearch = "") => {

    if(newDepartmentLeftSearch.length > 0) {

      if(newDepartmentLeftSearch === 'All') {

        if(nameAndId.length > 0) {

          const leftData = [...left, ...data]

          const leftFilterEmployee = leftData.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              //|| test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeftSearch(leftFilterEmployee)
        } else {
          setLeftSearch([...left, ...data])
        }

      } else {

        if(nameAndId.length > 0) {

          const leftFilterDepartment = left.filter((test) => {
            return (test.department_name === departmentLeftSearch)
          })

          const leftFilterEmployee = leftFilterDepartment.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              //|| test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeftSearch(leftFilterEmployee)
        } else {
          const leftDataResult = [...left, ...data]
          const leftFilterDepartment = leftDataResult.filter((test) => {
            return (test?.department_name.toLowerCase() === departmentRightSearch.toLowerCase())
          })
          setLeftSearch(leftFilterDepartment)
        }
      }

    } else {

      if(departmentLeftSearch.length > 0) {
        if(departmentLeftSearch === 'All') {

          if(nameAndId.length > 0) {

            const leftData = [...left, ...data]

            const leftFilterEmployee = leftData.filter((test) => {
              return (
                (test.fullName
                  ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                  : "") ||
                test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
                // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
              );
            });
            setLeftSearch(leftFilterEmployee)
          } else {
            setLeftSearch([...left, ...data])
          }

        } else {

          if(nameAndId.length > 0) {

            const leftFilterDepartment = left.filter((test) => {
              return (test.department_name === departmentLeftSearch)
            })

            const leftFilterEmployee = leftFilterDepartment.filter((test) => {
              return (
                (test.fullName
                  ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                  : "") ||
                test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
                // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
              );
            });
            setLeftSearch(leftFilterEmployee)
          } else {
            const leftDataResult = [...left, ...data]
            const leftFilterDepartment = leftDataResult.filter((test) => {
              return (test?.department_name.toLowerCase() === departmentRightSearch.toLowerCase())
            })
            setLeftSearch(leftFilterDepartment)
          }
        }
      }
    }
  }

  const handleAllRight = () => {

    if(departmentLeftSearch.length > 0) {

      if(departmentLeftSearch === 'All') {

        if(nameAndId.length > 0) {

          const leftFilterEmployee = left.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          const testLeft = not(left, leftFilterEmployee)
          setLeft(testLeft)
          setLeftSearch([])
          setRight(right.concat(leftFilterEmployee));
          setChecked([])
          setRightSearchHandleRight(leftFilterEmployee)


          setDepartmentRightSearch(departmentLeftSearch)
        } else {
          setLeft([])
          setChecked([])
          setLeftSearch([])
          setRight(right.concat(left));

          setRightSearchHandleRight(left, departmentLeftSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentLeftSearch.toLowerCase())
          setRightDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
          setDepartmentRightSearch(departmentLeftSearch)

        }

      } else {


        if(nameAndId.length > 0) {

          const leftFilterDepartment = left.filter((test) => {
            return (test.department_name === departmentLeftSearch)
           })

          const leftFilterEmployee = leftFilterDepartment.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeft(not(left, leftFilterEmployee))
          setLeftSearch([])
          setRight(right.concat(leftFilterEmployee));
          setChecked([])
          setRightSearchHandleRight(leftFilterEmployee)

          setDepartmentRightSearch(departmentLeftSearch)
        } else {
          setLeft([])
          setChecked([])
          setLeftSearch([])
          setRight(right.concat(left));
          setRightSearchHandleRight(left, departmentLeftSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentLeftSearch.toLowerCase())
          setRightDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
          setDepartmentRightSearch(departmentLeftSearch)
        }
      }
    }

    setIsDepartmentRightSearchEmpty(true)

  };

  const handleCheckedRight = () => {

    if(departmentLeftSearch.length > 0) {
      if(departmentLeftSearch === 'All') {

        if(nameAndId.length > 0) {

          const leftFilterEmployee = left.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeftSearch( not(leftFilterEmployee, leftChecked) )
          setLeft(not(left, leftChecked))
          setRight(right.concat(leftChecked));
          setRightSearchHandleRight(leftChecked)
          setChecked([])
        } else {
          setLeft(not(left, leftChecked))
          setLeftSearch(not(left, leftChecked))
          setChecked([])
          setRight(right.concat(leftChecked))

          setRightSearchHandleRight(leftChecked, departmentLeftSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentLeftSearch.toLowerCase())
          setRightDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
          setDepartmentRightSearch(departmentLeftSearch)
        }

      } else {

        if(nameAndId.length > 0) {
          const leftFilterDepartment = left.filter((test) => {
            return (test.department_name === departmentLeftSearch)
           })

          const leftFilterEmployee = leftFilterDepartment.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeft(not(left, leftFilterEmployee))
          setLeftSearch([])
          setRight(right.concat(leftFilterEmployee));
          setChecked([])
          setRightSearchHandleRight(leftFilterEmployee)

        } else {
          const leftFilterDepartment = left.filter((test) => {
            return (test.department_name === departmentLeftSearch)
           })
          setDepartmentRightSearch(departmentLeftSearch)
          setLeft(not(left, leftChecked))
          setChecked([])
          setLeftSearch(not(leftFilterDepartment, leftChecked))
          setRight(right.concat(leftChecked));
          setRightSearchHandleRight(leftChecked, departmentLeftSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentLeftSearch.toLowerCase())
          setRightDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
        }
      }
    }

    setIsDepartmentRightSearchEmpty(true)
  };

  const handleCheckedLeft = () => {

    if(departmentRightSearch.length > 0) {

      if(departmentRightSearch === 'All') {

        if(nameAndIdRight.length > 0) {

          const rightFilterEmployee = right.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });

          setRight(not(right, rightChecked))
          setRightSearch(not(rightFilterEmployee, rightChecked))
          setLeft(left.concat(rightChecked));
          setChecked([])
          setLeftSearchHandleLeft(rightChecked)

        } else {
          setRight(not(right, rightChecked))
          setRightSearch(not(right, rightChecked))
          setChecked([])
          setLeft(left.concat(rightChecked));
          setLeftSearchHandleLeft(rightChecked, departmentRightSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentRightSearch.toLowerCase())
          setLeftDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})

        }
      } else {
        if(nameAndIdRight.length > 0) {

          const rightFilterDepartment = right.filter((test) => {
            return (test.department_name === departmentRightSearch)
           })

          const rightFilterEmployee = rightFilterDepartment.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });

          setRightSearch(not(rightFilterEmployee, rightChecked))
          setRight(not(right, rightChecked))
          setChecked([])
          setLeft(left.concat(rightChecked))
          setLeftSearchHandleLeft(rightChecked)

        } else {

          const rightFilterDepartment = right.filter((test) => {
            return (test.department_name === departmentRightSearch)
          })

          setRightSearch(not(rightFilterDepartment, rightChecked))
          setRight(not(right, rightChecked))
          setChecked([])
          setLeft(left.concat(rightChecked))
          setLeftSearchHandleLeft(rightChecked, departmentRightSearch)


          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentRightSearch.toLowerCase())
          setLeftDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
          setDepartmentLeftSearch(departmentRightSearch)

        }

      }
    }
    setCheckedRight([])
    setIsDepartmentLeftSearchEmpty(true)

  };

  const handleAllLeft = () => {
    if(departmentRightSearch.length > 0) {

      if(departmentRightSearch === 'All') {

        if(nameAndIdRight.length > 0) {

          const rightFilterEmployee = right.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });
          setRight(not(right, rightFilterEmployee))
          setRightSearch([])
          setLeft(left.concat(rightFilterEmployee));
          setChecked([])
          setLeftSearchHandleLeft(rightFilterEmployee)

        } else {
          setRight([]);
          setLeft(left.concat(right))
          setChecked([])
          setRightSearch([])
          setLeftSearchHandleLeft(right, departmentRightSearch)
          const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentRightSearch.toLowerCase())
          setLeftDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})

        }
      }

     else {

      if(nameAndIdRight.length > 0) {
        const rightFilterDepartment = right.filter((test) => {
          return (test.department_name === departmentLeftSearch)
         })

        const rightFilterEmployee = rightFilterDepartment.filter((test) => {
          return (
            (test.fullName
              ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              : "") ||
            test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
          );
        });

        setLeft(not(right, rightFilterEmployee))
        setRight(left.concat(rightFilterEmployee));
        setChecked([])
        setRightSearch([])
        setLeftSearchHandleLeft(rightFilterEmployee)

      } else {
        setRight([])
        setChecked([])
        setRightSearch([])
        setLeft(left.concat(right))
        setLeftSearchHandleLeft(right, departmentRightSearch)
        const filterDepartment = departments.filter((test) => test.name.toLowerCase() === departmentRightSearch.toLowerCase())
        setLeftDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
      }
    }
    setCheckedRight([])
    setIsDepartmentLeftSearchEmpty(true)
  }
  };

  const numberOfChecked = (items: EMPTYPES[]) : number => {
    return intersection(checked, items).length

  }
  const numberOfCheckedRight = (items: EMPTYPES[]) : number => {
    return intersection(checkedRight, items).length
   }

  const handleToggleAll = (items: EMPTYPES[]) => (event: React.MouseEvent<Element, MouseEvent>) => {
    if(leftDepartmentValue.name === "All" || leftDepartmentValue.name.length > 0) {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    }
  }

  const handleToggleAllRight = (items: EMPTYPES[]) => (event: React.MouseEvent<Element, MouseEvent>) => {
    if(rightDepartmentValue.name === "All" || rightDepartmentValue.name.length > 0) {
      if (numberOfCheckedRight(items) === items.length) {
        setCheckedRight(not(checkedRight, items));
      } else {
        setCheckedRight(union(checkedRight, items));
      }
    }
  }

  // search by typing from left
  const handleSetNameAndId = (value: string) => {
    setNameAndId(value);
  }

  useEffect(() => {
    if(nameAndId !== "") {
      if(departmentLeftSearch.length > 0) {
        if(departmentLeftSearch === 'All') {
          const filteredEmployeeLeft = left.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });
          setLeftSearch(filteredEmployeeLeft)

        } else {

          const filterLeftDepartmentSearchNameAndId = left.filter((test) => {
            return ( (test.department_name == departmentLeftSearch) );
          });
          const filteredEmployeeLeft = filterLeftDepartmentSearchNameAndId.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
            );
          });

          setLeftSearch(filteredEmployeeLeft)
        }

      } else {
        const filteredEmployeeLeft = left.filter((test) => {
          return (
            (test.fullName
              ? test?.fullName.toLowerCase().includes(nameAndId?.toLowerCase())
              : "") ||
            test?.employeeCode.toLowerCase().includes(nameAndId?.toLowerCase())
            // || test?.department_name.toLowerCase().includes(nameAndId?.toLowerCase())
          );
        });

        setLeftSearch(filteredEmployeeLeft)
      }

    } else {

      if(departmentLeftSearch.length > 0) {

        if(departmentLeftSearch === 'All') {

          setLeftSearch(left)

        } else {

          const filterLeftDepartmentSearchNameAndId = left.filter((test) => {
            return ( (test.department_name == departmentLeftSearch) );
          });
          setLeftSearch(filterLeftDepartmentSearchNameAndId)
        }

      }

    }

  }, [nameAndId])

  // search by typing from right
  const handleSetNameAndIdRight = (value: string) => {
    setNameAndIdRight(value);
  }

  useEffect(() => {
    if(nameAndIdRight !== "") {
      if(departmentRightSearch.length > 0) {

        if(departmentRightSearch === 'All') {
          const filteredEmployeeRight = right.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });
          setRightSearch(filteredEmployeeRight)
        } else {

          const filterRightDepartmentSearchNameAndId = right.filter((test) => {
            return ( (test.department_name == departmentRightSearch) );
          });
          const filteredEmployeeRight = filterRightDepartmentSearchNameAndId.filter((test) => {
            return (
              (test.fullName
                ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase())
                : "") ||
              test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
              // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            );
          });
          setRightSearch(filteredEmployeeRight)
        }

      } else {
        const filteredEmployeeRight = right.filter((test) => {
          return (
            (test.fullName ? test?.fullName.toLowerCase().includes(nameAndIdRight?.toLowerCase()) : "") ||
            test?.employeeCode.toLowerCase().includes(nameAndIdRight?.toLowerCase())
            // || test?.department_name.toLowerCase().includes(nameAndIdRight?.toLowerCase())
          );
        });

        setRightSearch(filteredEmployeeRight)
      }

    }  else {

      if(departmentRightSearch.length > 0) {

        if(departmentRightSearch === 'All') {

          setRightSearch(right)

        } else {

          const filterRightDepartmentSearchNameAndId = right.filter((test) => {
            return ( (test.department_name == departmentRightSearch) );
          });
          setRightSearch(filterRightDepartmentSearchNameAndId)
        }

      }

    }
  }, [nameAndIdRight])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue: string = (event.target as HTMLInputElement).value
    if(radioValue && radioValue == "no") {
      setOverwrite(radioValue);

      const empIDs: string[] = [];
      right.forEach((obj) => {empIDs.push(obj.id)} );
      const data = {
        assigned_start: start_date.toISOString().slice(0, 10),
        assigned_end: end_date.toISOString().slice(0, 10),
        employee_id: empIDs,
        assigned_type: "employee",
        overwrite: radioValue,
        timesheet: OutGlobalAutocomplete.id,
      };

      try {
        const result = await ScheduleService.addSchedule(data);
      } catch (e) {
        console.log("Overlapping " + e)
      }

    } else {
      setOverwrite(radioValue);
    }

  };

  function isValidDate(date: any) {
    return (
      date instanceof Date &&
      !isNaN(date.getTime()) &&
      date.getFullYear() > 2000
    );
  }

  const onSubmit = async () => {
    if (
      !start_date ||
      !end_date ||
      !isValidDate(start_date) ||
      !isValidDate(end_date)
    ) {
      enqueueSnackbar(
        t("att.timesheet.Please select valid start date and end date"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    } else if (start_date > end_date){
        enqueueSnackbar(
          t("att.timesheet.Start date is greater than end date"),
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }
        );
    } else if (right.length == 0) {
      enqueueSnackbar(
        t("att.timesheet.Employee is required, please select Employee"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    } else if (
      OutGlobalAutocomplete == null || OutGlobalAutocomplete.id == "notimesheet" ||
      OutGlobalAutocomplete.id === ""
    ) {
      enqueueSnackbar(
        t("att.timesheet.Timesheet is required, please select Timesheet"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    } else {
      const empIDs: string[] = [];
      right.forEach((obj) => {empIDs.push(obj.id)} );
      const data = {
        assigned_start: start_date.toISOString().slice(0, 10),
        assigned_end: end_date.toISOString().slice(0, 10),
        employee_id: empIDs,
        assigned_type: "employee",
        overwrite: overwrite,
        timesheet: OutGlobalAutocomplete.id,
      };
      setLoading(true);
      setEnableClick(1)
      try {
        const result = await ScheduleService.addSchedule(data);
        if (result.status < 400) {
          setLoading(false);
          setEnableClick(0)
          enqueueSnackbar(t("common.Success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          navigate(WebURL.ATT_SCHEDULE);
        } else {
          setLoading(false);
          setEnableClick(0)
        }
      } catch (e) {
        // console.log(e)
      } finally {
        setLoading(false);
        setEnableClick(0)
      }
    }
  };

  const handleTimeSheet = (event: any) => {
    if(event !== "notimesheet") {
      setNewTimeSheet("");
      setTimeSheet(event.target.value);
      const timeSheet = event.target.value;
      SetOutGlobalAutocomplete({id: timeSheet})
    }
  }

  const handleDepartment = (value: string) => {

    if(value.length > 0) {
      const filterDepartment = departments.filter((test) => test.name.toLowerCase() === value.toLowerCase())
      setLeftDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
    } else {
      setLeftDepartmentValue({id: "", name: "", code: ""})
      setChecked([]);
    }

    if(value === 'All') {
      setDepartmentLeftSearch(value)
      setLeftSearch(left)
      setIsDepartmentLeftSearchEmpty(true)

    } else if(value.length === 0) {
      setDepartmentLeftSearch(value)
      setIsDepartmentLeftSearchEmpty(false)

    }else if((value.length > 0) && (value !== 'All')) {
      const filteredEmployeeDepartmentLeft = left.filter((test) => {
        return ( (test.department_name === value) );
      });
      setIsDepartmentLeftSearchEmpty(true)
      setDepartmentLeftSearch(value)
      setLeftSearch(filteredEmployeeDepartmentLeft)

      if(filteredEmployeeDepartmentLeft.length === 0){
        setIsDepartmentLeftSearchEmpty(false)
      }
    }

  }

  const handleDepartmentRight = (value: string) => {

    if(value.length > 0) {
      const filterDepartment = departments.filter((test) => test.name.toLowerCase() === value.toLowerCase())
          setRightDepartmentValue({id: filterDepartment[0].id, name: filterDepartment[0].name, code: filterDepartment[0].code})
    } else {
      setRightDepartmentValue({id: "", name: "", code: ""})
    }


    if(value === 'All') {
      setDepartmentRightSearch(value)
      setRightSearch(right)
      setIsDepartmentRightSearchEmpty(true)
    } else if(value.length === 0) {
      setDepartmentRightSearch(value)
      setIsDepartmentRightSearchEmpty(false)
    } else if((value.length > 0) && (value !== 'All')) {
      const filteredEmployeeDepartmentRight = right.filter((test) => {
        return ( (test.department_name == value) );
      });

      setDepartmentRightSearch(value)
      setRightSearch(filteredEmployeeDepartmentRight)
      setIsDepartmentRightSearchEmpty(true)
      if(filteredEmployeeDepartmentRight.length === 0) {
        setIsDepartmentRightSearchEmpty(false)
      }
    }

  }

  const getNewlyAddedTimeSheet = (value: string) => {
    setNewTimeSheet(value);

    if(value) {
      GenericService.list(ATTEndpoints.ATT_TIMESHEET_URI, {
        name: value,
      }).then((data) => {
        SetOutGlobalAutocomplete({id: data.data.data[0].id})
      })
    }
  }

  const handleUnSelectAll = () => {
    setChecked([]);
  }

  return (
    <>
    <Grid container={true} sx={{ pointerEvents: (enableClick === 1) ? "none" : "initial" }}>
      <CustomBreadcrumbs title={t("att.timesheet.New Schedule")} />
      <Grid item xs={12}>
        <Grid container={true} component={"form"} columnSpacing={1} rowSpacing={2}>
        {/* start */}
        <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={2.5}>
                    <DatePicker label={t("att.timecard.Start Date")} value={start_date}
                        onChange={(newValue: any) => {
                          if (isValidDate(newValue) && end_date) {
                            if (
                              newValue.toISOString().slice(0, 10) >
                              end_date.toISOString().slice(0, 10)
                            ) {
                              enqueueSnackbar(
                                t("att.timesheet.Start date is greater than end date"),
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                }
                              );
                              setStartDate(newValue);
                            }
                            setStartDate(newValue);
                          } else {
                            setStartDate(newValue);
                          }
                        }}
                      />
                  </Grid>
                  <Grid item  xs={12} md={2.5}>
                    <DatePicker label={t("att.timecard.End Date")} value={end_date}
                      onChange={(newValue: any) => {
                        if (isValidDate(newValue) && start_date) {
                          if (
                            newValue.toISOString().slice(0, 10) <
                            start_date.toISOString().slice(0, 10)
                          ) {
                            enqueueSnackbar(t("att.timesheet.End date is less than start date"), {
                              variant: "error",
                              autoHideDuration: 3000,
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            });
                            return;
                          }
                          setEndDate(newValue);
                        } else {
                          setEndDate(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item  xs={12} md={3}>
                    <Grid container direction="row">
                      <Grid item xs={12} md={7}>
                        <TimeSheetSelect timeSheet={timeSheet} handleTimeSheet={handleTimeSheet}
                          datafrom={ATTEndpoints.ATT_TIMESHEET_URI} id={"schedule-timesheet-select"}
                          newTimeSheet={newTimeSheet}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Box sx={{ display: {paddingLeft: 5}, cursor: "pointer", }}>
                          <AddIcon onClick={() => {setReload(false); setOpen(true)}}></AddIcon>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* end */}

          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"}>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group" value={overwrite}
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label={t("att.timesheet.Yes")} sx={{ color: "#212121"}}/>
                <FormControlLabel value="no" control={<Radio />} label={t("att.timesheet.No")}
                  sx={{ color: "#212121"}}
                />
              </RadioGroup>
              <Typography fontSize={17} sx={{ marginRight: "7px", color: "#212121" }}>
                {t(
                  "att.timesheet.Overwrite existing timesheet during this period"
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} component="div" sx={{height: 30}}/>
          <Grid container>
            <Grid item xs={12} md={5.5}>
              { (nameAndId || departmentLeftSearch) && leftSearch?.length > 0 ?

                (
                  <>
                  {/* <div style={{ height: "30px"}}>{isFirstLoaded} afdee</div> */}

                <TransferListLeft items={leftSearch} checked={checked} handleToggle={handleToggle}
                handleSetNameAndId={handleSetNameAndId} nameAndId={nameAndId}
                departments={departments} handleDepartment={handleDepartment}
                handleToggleAll={handleToggleAll} numberOfChecked={numberOfChecked} handleUnSelectAll={handleUnSelectAll}
                departmentLeftSearch={departmentLeftSearch}
                leftDepartmentValue={leftDepartmentValue}
                />
                </>
                ) :

                  (nameAndId || departmentLeftSearch) ? (
                    <>
                    {/* <div style={{paddingBottom: "100px", height: "30px", width: "100px", backgroundColor: "red"}}>zz {left.length}</div> */}

                    <TransferListLeft items={[]} checked={checked} handleToggle={handleToggle}
                      handleSetNameAndId={handleSetNameAndId} nameAndId={nameAndId}
                      departments={departments} handleDepartment={handleDepartment}
                      handleToggleAll={handleToggleAll} numberOfChecked={numberOfChecked} handleUnSelectAll={handleUnSelectAll}
                      departmentLeftSearch={departmentLeftSearch}
                      leftDepartmentValue={leftDepartmentValue}
                    />
                    </>
                  ) : (
                    <TransferListLeft items={left} checked={checked} handleToggle={handleToggle}
                      handleSetNameAndId={handleSetNameAndId} nameAndId={nameAndId}
                      departments={departments} handleDepartment={handleDepartment}
                      handleToggleAll={handleToggleAll} numberOfChecked={numberOfChecked} handleUnSelectAll={handleUnSelectAll}
                      departmentLeftSearch={departmentLeftSearch}
                      leftDepartmentValue={leftDepartmentValue}
                    />
                  )
                }
            </Grid>
            <Grid item xs={12} md={1}>
              <TransferedClick left={left} right={right} leftChecked={leftChecked} rightChecked={rightChecked}
                handleCheckedLeft={handleCheckedLeft} handleAllRight={handleAllRight}
                handleCheckedRight={handleCheckedRight} handleAllLeft={handleAllLeft}
                isDepartmentLeftSearchEmpty={isDepartmentLeftSearchEmpty}
                isDepartmentRightSearchEmpty={isDepartmentRightSearchEmpty}
                isTotalPageSame={isTotalPageSame}
                 />
            </Grid>
            <Grid item xs={12} md={5.5}>
              {(nameAndIdRight || departmentRightSearch) && rightSearch.length > 0 ? (
                <TransferListRight items={ rightSearch } rightChecked={checkedRight}
                  handleToggleRight={handleToggleRight} handleSetNameAndIdRight={handleSetNameAndIdRight}
                  nameAndIdRight={nameAndIdRight} departments={departments} handleDepartmentRight={handleDepartmentRight}
                  handleToggleAllRight={handleToggleAllRight} numberOfCheckedRight={numberOfCheckedRight}
                  departmentRightSearch={departmentRightSearch}
                  rightDepartmentValue={rightDepartmentValue}
                  />
              ) :
                (nameAndIdRight || departmentRightSearch) ? (
                  <TransferListRight items={[]} rightChecked={checkedRight}
                    handleToggleRight={handleToggleRight} handleSetNameAndIdRight={handleSetNameAndIdRight}
                    nameAndIdRight={nameAndIdRight} departments={departments} handleDepartmentRight={handleDepartmentRight}
                    handleToggleAllRight={handleToggleAllRight} numberOfCheckedRight={numberOfCheckedRight}
                    departmentRightSearch={departmentRightSearch}
                    rightDepartmentValue={rightDepartmentValue}
                    />
                ) : (

                  <TransferListRight items={right} rightChecked={checkedRight}
                    handleToggleRight={handleToggleRight} handleSetNameAndIdRight={handleSetNameAndIdRight}
                    nameAndIdRight={nameAndIdRight} departments={departments} handleDepartmentRight={handleDepartmentRight}
                    handleToggleAllRight={handleToggleAllRight} numberOfCheckedRight={numberOfCheckedRight}
                    departmentRightSearch={departmentRightSearch}
                    rightDepartmentValue={rightDepartmentValue}
                    />

                )
              }
              <Box sx={{
                  position: "relative",
                  width: "90%",
                  padding: {
                      xs: '10px 25px 25px 25px',
                      sm: '10px 25px 25px 25px',
                      md: '10px 26px 25px 25px',
                      lg: '10px 26px 25px 25px',
                      xl: '10px 33px 30px 30px',
                     },
              }}>
                <Grid item xs={12}  >
                  <Stack spacing={2} direction={"row"} sx={{position: "absolute", right: 0}}>
                    <LoadingButton sx={{ width: 90 }} id="SaveButton" variant={"contained"} loading={loading}
                        onClick={async () => {
                          await onSubmit();
                        }}
                        >
                        {t("common.Save")}
                      </LoadingButton>
                      <Button id="CancelButton" variant={"outlined"}
                        onClick={() => {
                          navigate(WebURL.ATT_SCHEDULE);
                        }}
                      >
                        {t("common.Cancel")}
                      </Button>
                    </Stack>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <AddNewTimeSheet open={open} close={() => { setReload(true); setOpen(false) }}
      getNewlyAddedTimeSheet={getNewlyAddedTimeSheet}/>
    </>
  );
};

export default Schedule;
