import WebURL from "@/urls";

/***
 * 权限依赖项的id为key, 权限被依赖项的id集合为value
 */
const PermissionDependencies: {
  [key: string]: Array<string>
} = {
  // RECORD
  "ZLINKATT_RECORD_PUNCH_UPLOAD": ["ZLINKATT_RECORD_PUNCH_LIST"],

  // REPORT
  "ZLINKATT_REPORT_MONTHLY_EXPORT": ["ZLINKATT_REPORT_MONTHLY_LIST"],
  "ZLINKATT_REPORT_FIRSTLAST_EXPORT": ["ZLINKATT_REPORT_FIRSTLAST_LIST"],

  // ATT
  "ZLINKATT_RULE_SCHEDULE_MANAGE": ["ZLINKATT_RULE_SCHEDULE_LIST"],
  "ZLINKATT_RULE_SCHEDULE_ADD": ["ZLINKATT_RULE_SCHEDULE_LIST"],

  // RULE
  "ZLINKATT_RULE_TIMESHEET_MANAGE": ["ZLINKATT_RULE_TIMESHEET_LIST"],
  "ZLINKATT_RULE_TIMESHEET_ADD": ["ZLINKATT_RULE_TIMESHEET_LIST"],
  "ZLINKATT_RULE_CLOCKING_MANAGE": ["ZLINKATT_RULE_CLOCKING_LIST"],

  // DMS
  "ZLINKATT_DMS_DEVICE_ADD": ["ZLINKATT_DMS_DEVICE_LIST"],
  "ZLINKATT_DMS_DEVICE_MANAGE": ["ZLINKATT_DMS_DEVICE_LIST"],
  "ZLINKATT_DMS_DEVICE_PERSON": ["ZLINKATT_DMS_DEVICE_LIST", "ZLINKATT_DMS_DEVICE_MANAGE"],

  // SITEMGT
  "ZLINKATT_SITEMGT_ZONE_MANAGE": ["ZLINKATT_SITEMGT_ZONE_LIST"],
  "ZLINKATT_SITEMGT_SITE_MANAGE": ["ZLINKATT_SITEMGT_SITE_LIST"],

  // ORG
  "ZLINKATT_ORG_PERSON_MANAGE": ["ZLINKATT_ORG_PERSON_LIST"],

  // BASE

}

export const dependency = (PermissionMap: Map<string, string>) => {
  const depend = new Map<string, Array<string>>();
  for (const permissionDep in PermissionDependencies) {
    const subDepend: Array<string> = [];
    PermissionDependencies[permissionDep].forEach((item: string) => {
      subDepend.push(PermissionMap.get(item)!);
    })
    depend.set(PermissionMap.get(permissionDep)!, subDepend);
  }
  return depend;
}


export const OWNER = "Owner";
export const SUPERADMIN = "SuperAdmin";
export const OWNER_CODE = "ORGANIZATION OWNER";
export const SUPERADMIN_CODE = "SUPERADMIN";

/***
 * 左侧是url path， 右侧是对应在后端的权限名。 如果权限名有更改，需更改此部分
 ***/
export const URL2PERMS = new Map([
  // [WebURL.DASHBOARD, "BASE_DASHBOARD_VIEW"], //在dashboard组件中自行判断

  [WebURL.HR_EMPLOYEE, "ORG_PERSON_LIST"],
  [WebURL.HR_EMPLOYEE_VIEW, "ORG_PERSON_MANAGE"],

  [WebURL.LOCATION_SITE, "SITEMGT_SITE_LIST"],
  [WebURL.LOCATION_SITE_VIEW, "SITEMGT_SITE_MANAGE"],
  [WebURL.LOCATION_ZONE, "SITEMGT_ZONE_LIST"],
  [WebURL.LOCATION_ZONE_VIEW, "SITEMGT_ZONE_MANAGE"],

  [WebURL.DMS_DEVICE, "DMS_DEVICE_LIST"],
  [WebURL.DMS_DEVICE_ADD, "DMS_DEVICE_MANAGE"],
  [WebURL.DMS_DEVICE_VIEW, "DMS_DEVICE_ADD"],
  [WebURL.ATT_DEVICE_PERSONS, "DMS_DEVICE_PERSON"],

  // [WebURL.ATT_DEVICE_LIST, "RULE_CLOCKING_LIST"],
  [WebURL.ATT_TIMESHEET, "RULE_TIMESHEET_LIST"],
  // [WebURL.ATT_TIMESHEET_VIEW, "RULE_TIMESHEET_MANAGE"],
  [WebURL.ATT_TIMESHEET_NEW, "RULE_TIMESHEET_ADD"],

  [WebURL.ATT_SCHEDULE, "RULE_SCHEDULE_LIST"],
  [WebURL.ATT_SCHEDULE_NEW, "RULE_SCHEDULE_ADD"],

  [WebURL.ATT_REPORT_FIRST_LAST, "REPORT_FIRSTLAST_LIST"],
  [WebURL.ATT_REPORT_MONTHLY_REPORT, "REPORT_MONTHLY_LIST"],

  [WebURL.ATT_TIMECARD_PUNCH_RECORD, "RECORD_PUNCH_LIST"],
  [WebURL.ATT_TIMECARD_TIMECARD, "RECORD_TIMECARD_LIST"],

])

export const URL2PERMS2 = new Map([
  ["ZLINKATT_BASE_DASHBOARD_VIEW", WebURL.DASHBOARD],

  ["ZLINKATT_ORG_ROLE_LIST", WebURL.AUTH_ROLE],
  // [WebURL.HR_EMPLOYEE, "ZLINKATT_ORG_PERSON_MANAGE"],
  ["ZLINKATT_ORG_PERSON_LIST", WebURL.HR_EMPLOYEE],


  ["ZLINKATT_SITEMGT_ZONE_LIST", WebURL.LOCATION_ZONE],
  // [WebURL.HR_EMPLOYEE, "ZLINKATT_SITEMGT_ZONE_MANAGE"],
  // [WebURL.HR_EMPLOYEE, "ZLINKATT_SITEMGT_SITE_MANAGE"],
  ["ZLINKATT_SITEMGT_SITE_LIST", WebURL.LOCATION_SITE],

  // [WebURL.LOCATION_SITE, "ZLINKATT_RULE_SCHEDULE_MANAGE"],
  ["ZLINKATT_RULE_SCHEDULE_ADD", WebURL.ATT_SCHEDULE_NEW],
  ["ZLINKATT_RULE_SCHEDULE_LIST", WebURL.ATT_SCHEDULE],
  // [WebURL.LOCATION_SITE, "ZLINKATT_RULE_TIMESHEET_MANAGE"],
  ["ZLINKATT_RULE_TIMESHEET_ADD", WebURL.ATT_TIMESHEET_NEW],
  ["ZLINKATT_RULE_TIMESHEET_LIST", WebURL.ATT_TIMESHEET],


  ["ZLINKATT_REPORT_MONTHLY_EXPORT", '/monthly/export'],
  ["ZLINKATT_REPORT_MONTHLY_LIST", WebURL.ATT_REPORT_MONTHLY_REPORT],
  ["ZLINKATT_REPORT_FIRSTLAST_EXPORT", '/first_last/export/'],
  ["ZLINKATT_REPORT_FIRSTLAST_LIST", WebURL.ATT_REPORT_FIRST_LAST],

  ["ZLINKATT_RECORD_TIMECARD_LIST", WebURL.ATT_TIMECARD_TIMECARD],
  ["ZLINKATT_RECORD_PUNCH_UPLOAD", '/devices/transactions'],
  ["ZLINKATT_RECORD_PUNCH_LIST", WebURL.ATT_TIMECARD_PUNCH_RECORD],

  ["ZLINKATT_DMS_DEVICE_LIST", WebURL.DMS_DEVICE],
  // [WebURL.ATT_TIMECARD_PUNCH_RECORD, "ZLINKATT_DMS_DEVICE_MANAGE"],
  ["ZLINKATT_DMS_DEVICE_ADD", WebURL.DMS_DEVICE_ADD],
  // [WebURL.ATT_TIMECARD_PUNCH_RECORD, "ZLINKATT_DMS_DEVICE_PERSON"],

])

