import React from "react";
import {AccessTime} from "@mui/icons-material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import WebURL from "../../../urls";
import {useTranslation} from "react-i18next";
import EligibleMenu from "@/pages/index"

const TimecardHome: React.FC = () => {
  const {t} = useTranslation();
  const items = [
    {name: "punch_list", title: t("att.timecard.Clocking Records"), icon: AccessTime, path: WebURL.ATT_TIMECARD_PUNCH_RECORD},
    {name: "timecard_list", title: t("att.timecard.Timecard"), icon: AssessmentIcon, path: WebURL.ATT_TIMECARD_TIMECARD},
  ]
  return (
    <EligibleMenu title={t("common.sidebar.Timecard")} menus={items}/>
  )
}
export default TimecardHome
