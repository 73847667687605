import React, {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import {grey} from "@mui/material/colors";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {Avatar, Box, Grid, Paper, Typography, Badge} from "@mui/material";
import GenericService from "@/services/GenericService";
import {DMSEndpoints} from "@/services/dms/endpoints";
import {useTranslation} from "react-i18next";

type DeviceProps = {
  alias?: string,
  site_name: string,
  zone_name: string,
  status: string,
  registration_device: boolean
}

const DeviceInfoHeader: React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation()
  const state = location.state as { id: string }
  const [device, setDevice] = useState<DeviceProps>({alias:"", site_name:"", zone_name:"", status:"", registration_device: false});

  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then((res) => {
      setDevice(res.data)
    })
  }, [])
  return (
    <>
      <Paper style={{ minHeight: '80px', backgroundColor: '#fff', marginTop: '20px' }}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
        <Grid item style={{display: "flex", alignItems: "center"}}  md={4}>
            <Badge
              color="success"
              variant="dot"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                '.MuiBadge-badge': {
                  backgroundColor: device.status === '1' ? "#20cf61" : "#ef5656",
                  bottom: "7px", right: "7px", border: `3px solid #fff`,
                  height: "15px",
                  width: "15px",
                  minWidth: "15px",
                  borderRadius: "15px",
                }
              }}
            >
            <Avatar sx={{ width: 66, height: 66, bgcolor: grey[100], marginLeft: 1}}>
              <AdUnitsIcon sx={{ fontSize: 50 }} color="action"/>
            </Avatar>
            </Badge>
            <Box style={{paddingLeft: "20px"}}>
              <Typography variant="h6">{device?.alias}</Typography>
              <Typography>{`${t("common.sidebar.Site")}: ${device?.site_name}`}</Typography>
              <Typography>{`${t("common.sidebar.Zone")}: ${device?.zone_name}`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
export default DeviceInfoHeader
