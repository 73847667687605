import React from "react";
import CustomDataGrid from "@/components/data-grid/CustomDataGrid";
import WebURL from "@/urls";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import {LocationEndpoints} from "@/services/location/endpoints";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import {timezones} from "@/components/resource/timezone";
import { Tooltip } from "@material-ui/core";

const SiteGird: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const timezoneList = timezones()
  const timezoneCol: GridColTypeDef = {
    type: "string",
    flex: 0.4,
    valueGetter: value => value.row.address.timeZone,
    renderCell: (params) => {
      const timezone = params.row.address.timeZone
      if(!timezone){
        return '-'
      }
      return timezoneList.find(option => option.value === timezone)?.name|| `UTC${timezone}`
    }
  }
  const columns: GridColDef[] = [
    {field: 'name', headerName: t('dms.Site Name'), flex: 0.3},
    {field: 'timezone', headerName: t('dms.Site Time Zone'), ...timezoneCol},
    {field: 'description', headerName: t('dms.Description'), flex: 0.2},
    {
      field: 'actions',
      headerName: t("common.Action"),
      type: "actions",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      renderCell: (cell) => {
        return (
           <Tooltip title={t('common.View')} placement="right">
            <div>
            <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
             key: "view",
             onClick: () => {
               navigate(WebURL.LOCATION_SITE_VIEW, {state: {id: cell.id}});
             }
           }} />
            </div>
           </Tooltip>
        )
      }
    },
  ]
  return (
    <CustomDataGrid
      uri={LocationEndpoints.SITE_URI}
      authedAction={"SITE_MANAGE"}
      columns={columns}
      checkboxSelection={false}
      toolbar={{
        title: t("common.sidebar.Site"),
        breadcrumbs: [
          {breadcrumbName: t("common.sidebar.Site Management"), path: WebURL.LOCATION_HOME },
          {breadcrumbName: t("common.sidebar.Site"), path: WebURL.LOCATION_SITE}
        ]
      }}
    />
  )
}
export default SiteGird
