import React, { useRef, Dispatch, SetStateAction } from "react";
import {Box, Button, Grid, Stack, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";

type FilterProps = {
  show: boolean;
  onSubmit: (params: any) => Promise<void>;
  setParams: Dispatch<SetStateAction<Record<any, any>>>;
  refresh?: (resetPage?: boolean|undefined) => void
}

const EmployeeFilterFC: React.FC<FilterProps> = (props) => {
  const {t} = useTranslation()

  const filterFormValidate = object({
    code: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {code: '', firstName: "", lastName: ""}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = filterForm;

  if (!props.show) {
    return (
      <></>
    )
  }

  return (
    <>
    <Grid sx={{marginBottom: '5px'}} component={'form'} onSubmit={handleSubmit(props.onSubmit)}>
      <Stack spacing={2} direction="row">

      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <TextField
          sx={{paddingRight: "50px", width: "400px"}}
            label={t("common.Person ID")}
            value={field.value}
            size="small"
            error={!!errors.code}
            helperText={errors.code?.message || ""}
            {...register("code")}
          />
        )}
      />
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <TextField
          sx={{paddingRight: "50px", width: "400px"}}
            label={t("signUp.First Name")}
            value={field.value}
            size="small"
            error={!!errors.firstName}
            helperText={errors.firstName?.message || ""}
            {...register("firstName")}
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <TextField
          sx={{paddingRight: "50px", width: "400px"}}
            label={t("signUp.Last Name")}
            value={field.value}
            size="small"
            error={!!errors.lastName}
            helperText={errors.lastName?.message || ""}
            {...register("lastName")}
          />
        )}
      />

      <Button
        id='SearchButton'
        variant={"contained"}
        type='submit'
      >
        {t("common.Search")}
      </Button>
      <Button
        id="ResetButton"
        variant={"outlined"}
        onClick={() => {
          filterForm.reset()
          props.setParams({})
          props.refresh && props.refresh(true);
        }}
      >
        {t("common.Reset")}
      </Button>
    </Stack>
    </Grid>
    </>
  )
}

export default EmployeeFilterFC;
