import React, {useRef, useState} from "react";
import {Box, Breadcrumbs, Button, Grid, IconButton, MenuItem, Select, Stack, styled} from "@mui/material";
import Paper from '@mui/material/Paper';
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import KnowAboutThisPolicy from "./KnowAboutThis"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


/**
 * Validators */

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  width: '100-15vw',
  height: '100-5vh',
};

const TypographyStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginLeft: "3vw",
  marginBottom:"10px"
}

const useStyles = makeStyles({
  root: {
      textAlign: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
      marginLeft: '40px',
      border: '1px solid #36C96D',
    },
});

const SelectedStyle = {
  fontSize: 12,
  textTransform: "none",
  "&.Mui-selected": {backgroundColor: '#36C96D', color: "#FFFFFF"},
  "&.Mui-selected:hover": {backgroundColor: '#36C96D', color: "#FFFFFF"}
}

const DisabledStyle = {
  fontSize: 12,
  textTransform: "none",
  "&.Mui-selected": {backgroundColor: 'grey.400', color: "#FFFFFF"},
  "&.Mui-selected:hover": {backgroundColor: 'grey.400', color: "#FFFFFF"}
}

const NewSubscription: React.FC = () => {
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const patternRef = useRef<{pattern: any}>();
  const sheetRef = useRef<{sheet: any}>();
  const [policy, setPolicy] = useState<string>("main");
  const [free_state, setFreeState] = React.useState('yearly');
  const [free_trial_state, setFreeTrialState] = React.useState('monthly');
  const [pro_state, setProState] = React.useState('yearly');
  const [plus_state, setPlusState] = React.useState('yearly');
  const classes = useStyles();
  const handleFreeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setFreeState(newAlignment);
  };
  const handleFreeTrailChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setFreeTrialState(newAlignment);
  };
  const handleProChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setProState(newAlignment);
  };
  const handlePlusChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setPlusState(newAlignment);
  };

  return (
    <Box>
      {policy === "know" ?
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex' }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
            <IconButton id='BackButton' sx={{padding: "5px"}} onClick={() => setPolicy("main")}>
              <ArrowBackIosNewIcon sx={{ display: "flex" }} fontSize="small" />
            </IconButton>
            <Typography>Know more about this?</Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
        :
      <Grid className={"toolbar-container"}>
        <CustomBreadcrumbs title={"Subscription"} />
      </Grid>}
      {policy === "know" ? <KnowAboutThisPolicy/> :
      <Box sx={{ ...commonStyles, borderColor: 'grey.500'}} >
        <Grid item xs={12} sx={{p: "20px 16px" , display: 'flex', justifyContent: 'end'}}>
            <Stack spacing={2} direction={"row"}>
              <Select
                size={"small"}
                defaultValue={"dollar"}
              >
                <MenuItem value={"dollar"}>Dollar</MenuItem>
              </Select>
            </Stack>
        </Grid>
        <Grid direction="row" sx={{p: "10px 0" }}>
          <Grid xs={12} container  justifyContent="start">
            <Grid xs={3} justifyContent="center">
            </Grid>
            <Grid xs={8} container  justifyContent="start" sx={{marginLeft:"3.5vw"}}>
              <Grid >
              <Typography sx={TypographyStyle}>Free</Typography>
              <ToggleButtonGroup
                value={free_state}
                size={"small"}
                exclusive
                disabled={true}
                sx={{borderRadius: '10%', color:"#000"}}
                className={classes.root}
                onChange={handleFreeChange}
              >
                <ToggleButton id='MonthlyButton' value="monthly" sx={SelectedStyle}>Monthly</ToggleButton>
                <ToggleButton id='YearlyButton' value="yearly"  sx={SelectedStyle}>Yearly</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
              <Grid >
                <Typography sx={TypographyStyle}>Free trial</Typography>
                <ToggleButtonGroup
                  className={classes.root}
                  size={"small"}
                  exclusive
                  disabled={true}
                  sx={{borderRadius: '10%'}}
                  value={free_trial_state}
                  onChange={handleFreeTrailChange}
                >
                  <ToggleButton id='MonthlyButton' value="monthly" sx={DisabledStyle}>Monthly</ToggleButton>
                  <ToggleButton id='YearlyButton' value="yearly"  sx={SelectedStyle}>Yearly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid >
                <Typography sx={TypographyStyle}>Pro</Typography>
                <ToggleButtonGroup
                  className={classes.root}
                  size={"small"}
                  exclusive
                  sx={{borderRadius: '10%'}}
                  value={pro_state}
                  onChange={handleProChange}
                >
                  <ToggleButton id='MonthlyButton' value="monthly" sx={SelectedStyle}>Monthly</ToggleButton>
                  <ToggleButton id='YearlyButton' value="yearly"  sx={SelectedStyle}>Yearly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid >
                <Typography sx={TypographyStyle}>Plus</Typography>
                <ToggleButtonGroup
                  className={classes.root}
                  size={"small"}
                  exclusive
                  sx={{borderRadius: '10%'}}
                  value={plus_state}
                  onChange={handlePlusChange}
                >
                  <ToggleButton id='MonthlyButton' value="monthly" sx={SelectedStyle}>Monthly</ToggleButton>
                  <ToggleButton id='YearlyButton' value="yearly"  sx={SelectedStyle}>Yearly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid direction="row" sx={{p: "3vh 8vw" }}>
          <Grid xs={12} container  justifyContent="start">
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Flexible Time Shift</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "5.5vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.1vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.3vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9vw"}}/>
            </Grid>
          </Grid>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>3 kinds report formats</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "5.5vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.1vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.3vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9vw"}}/>
            </Grid>
          </Grid>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Event picture</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#FFFFFF", marginLeft: "5.5vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.1vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9.3vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9vw"}}/>
            </Grid>
          </Grid>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>GPS fence clocking</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#FFFFFF", marginLeft: "5.5vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#FFFFFF", marginLeft: "9.1vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#FFFFFF", marginLeft: "9.3vw"}}/>
              <Grid item sx={{width: "5px", height: "5px", borderRadius: "4px", backgroundColor: "#36C96D", marginLeft: "9vw"}}/>
            </Grid>
          </Grid>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Events history storage</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Typography sx={{ marginLeft: "4vw"}}>6 months</Typography>
              <Typography sx={{ marginLeft: "5vw"}}>1 months</Typography>
              <Typography sx={{ marginLeft: "6vw"}}>1 year</Typography>
              <Typography sx={{ marginLeft: "7vw"}}>2 year</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{marginLeft: '3%', marginRight: '3%', backgroundColor: '#36C96D'}}/>
        <Grid direction="row" sx={{p: "1vh 8vw" }}>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Monthly price</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Typography sx={{ marginLeft: "4vw"}}>0 USD</Typography>
              <Typography sx={{ marginLeft: "7vw"}}>0 USD</Typography>
              <Typography sx={{ marginLeft: "6.5vw"}}>1.99 USD</Typography>
              <Typography sx={{ marginLeft: "5vw"}}>2.00 USD</Typography>
            </Grid>
          </Grid>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Yearly price</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Typography sx={{ marginLeft: "4vw"}}>0 USD</Typography>
              <Typography sx={{ marginLeft: "7vw"}}>0 USD</Typography>
              <Typography sx={{ marginLeft: "6.5vw"}}>19.9 USD</Typography>
              <Typography sx={{ marginLeft: "5vw"}}>29.9 USD</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{marginLeft: '3%', marginRight: '3%', backgroundColor: '#36C96D'}}/>
        <Grid direction="row" sx={{p: "1vh 8vw" }}>
          <Grid xs={12} container  justifyContent="start" sx={{"marginTop": "2vh"}}>
            <Grid xs={3} justifyContent="center">
              <Typography sx={TypographyStyle}>Buy</Typography>
            </Grid>
            <Grid xs={8} container  justifyContent="start">
              <Button id='TrialButton' sx={{ marginLeft: "13vw", borderRadius: "15%", textTransform: "none"}} variant="contained" onClick={() => navigate("/sub/payment/")}>Trial</Button>
              <Button id='ProButton' sx={{ marginLeft: "6vw", borderRadius: "15%", textTransform: "none"}} variant="contained" disabled>Pro</Button>
              <Button id='PlusButton' sx={{ marginLeft: "5.5vw", borderRadius: "15%", textTransform: "none"}} variant="contained" disabled>Plus</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid direction="row" sx={{p: "1vh 0" }}>
          <Grid xs={9} container  justifyContent="center" sx={{marginLeft: "15vw"}}>
            <Link
              underline={"none"}
              onClick={() => setPolicy("know")}
            >
              <Typography>Know more about this?</Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>}
    </Box>
  )
}
export default NewSubscription
