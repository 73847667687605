import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {Grid} from "@mui/material";
import Header from "../Header";
import SideBar from "../SideBar";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getInitialState} from "../../store/slices/App";
import jwtDecode from "jwt-decode";
import WebURL from "../../urls";
import {isAuthedAccess} from "@/utils/permissions/FilterUnauthed"

type MainLayoutProps = {
  children?: React.ReactNode;
}
type TokenProps = {
  exp: number;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const navigate = useNavigate();
  const loca = useLocation()
  const expiredRedriectUrl = process.env.REACT_APP_ZLINK_HOST ? 
    WebURL.ZKLINK_REDIRECT : WebURL.AUTH_USER_LOGIN;  // development
  useEffect(() => {
    const jwtToken = localStorage.getItem("accessToken");
    if(jwtToken){
      const decodedToken = jwtDecode<TokenProps>(jwtToken);
      // console.log("decodedToken >>>", decodedToken)
      const curStamp = Date.now() / 1000;
      if(curStamp > decodedToken.exp){
        //Token expired
        console.log("decodedToken >>> expired")
        navigate(expiredRedriectUrl);
      }
    }else{
        navigate(expiredRedriectUrl);
    }
    // 根据url路径判断该用户是否有权限访问对应模块
    // if (!isAuthedAccess(loca.pathname)){
    //   navigate(WebURL.OTHER_NO_PERMISSION)
    // }
  }, [])
  return (
    <Grid style={{backgroundColor: '#f4f6f7', width: '100%', height: '100%'}}>
      <Header/>
      <Grid style={{height: `calc(100% - 90px)`, margin: '12px', display: 'flex', flexDirection: 'row'}}>
        <SideBar/>
        <Grid container style={{height: '100%', overflow: 'auto', margin: '10px 16px', display: 'block'}}>
          <Outlet/>
        </Grid>
      </Grid>
      {loca.pathname === '/' ? <Navigate replace to='/dashboard' /> : null}
    </Grid>
  )
}
export default MainLayout
