const API_VER = 'v1.0'
export const ATTEndpoints = {
    ATT_PRESENT_SUMMARY_TODAY: `/att/api/${API_VER}/absent_summary/`,
    ATT_EMPLOYEE_PRESENT_STATUS_URI: `/att/api/${API_VER}/records/current_day_list/`,
    ATT_TIMESHEET_URI: `/att/api/${API_VER}/timesheet/`,
    ATT_SCHEDULE_URI: `/att/api/${API_VER}/schedules/`,
    ATT_SCHEDULE_EMPLOYEE_URI: `/att/api/${API_VER}/schedules_employee/`,
    ATT_SCHEDULE_DELETE_URI: `/att/api/${API_VER}/schedules/delete_schedules/`,
    ATT_SCHEDULE_DELETE_PERSON_URI: `/att/api/${API_VER}/schedules_employee/delete_person/`,
    ATT_SCHEDULE_BY_TIMESHEET_URI: `/att/api/${API_VER}/schedules/employee_by_timesheet/`,
    ATT_PUNCH_RECORD_URI: `/att/api/${API_VER}/records/`,
    ATT_TIMECARD_URI: `/att/api/${API_VER}/timecard/`,
    ATT_REPORT_FIRST_LAST: `/att/report/api/${API_VER}/first_last/`,
    ATT_REPORT_FILO: `/att/report/api/${API_VER}/filo/`,
    ATT_REPORT_MONTHLY_REPORT_EXPORT: `/att/report/api/${API_VER}/monthly/export/`,
    ATT_REPORT_MONTHLY_REPORT: `/att/report/api/${API_VER}/monthly/zlink_list/`,
    ATT_COLLECTION_RULE: `/att/api/${API_VER}/collection_rule/`,

    ATT_CALCTASK_LOG: `/att/api/${API_VER}/calculate_task_log/`,
    ATT_CALCTASK: `/att/api/${API_VER}/calculate_task/`,
}
