import React, {useRef, useState, useEffect} from "react";
import {Box, Button, Grid, Stack, TextField, Tooltip} from "@mui/material";
import TimesheetSetting from "./TimsheetSetting";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import PatternSetting, {PatternSettingFields} from "./PatternSetting";
import TimesheetService from "../../../../services/att/services/TimesheetService";
import { useNavigate, useParams, useLocation } from "react-router-dom"
import WebURL from "../../../../urls";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ControlTextField from "@/components/hook-form/ControlTextField";
import GenericService from "../../../../services/GenericService";
import { ATTEndpoints } from "../../../../services/att/endpoints";
import {useTranslation} from "react-i18next";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { useSnackbar } from "notistack";

const NewTimesheet: React.FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const state = location.state as { id: string }
  let timeSheetId: string | null = null
  if (state) {
    timeSheetId = state.id
  }

  const timesheetSchema = object({
    name: z.string().min(1, t("common.This field is required!")),
    description: z.string(),
    // cycle: z.number()
    //   .min(1, "Minimum cycle is 1")
    //   .max(12, "Maximum cycle is 12"),
    // period: z.string()
  })
  type TimesheetInput = TypeOf<typeof timesheetSchema>;
  const tsForm = useForm<TimesheetInput>({
    resolver: zodResolver(timesheetSchema),
    defaultValues: {
      name: '',
      description: ''
    }
  })

  const [descriptionToolTip, setDescriptionToolTip] = useState<string>('');
  const patternRef = useRef<{pattern: any}>();
  const sheetRef = useRef<{sheet: any}>();
  const [timesheet] = useState<any[]>([]);
  const [pattern] = useState<PatternSettingFields>({
    max_hrs: "24", pairing: "first_last", required_record: "any",
    day_change: "00:00", break_start: "12:00", break_end: "12:30", break_calculate_type: "auto_deducted"});
  const [periodCycle] = useState<number>(1);
  const [option, setOption] = React.useState('clocking');

  const onSubmit = async (formData: any) => {
    const sheetData = await sheetRef.current?.sheet();
    const patternData = await patternRef.current?.pattern();
    const mix_patternData = {...sheetData, ...patternData}
    delete mix_patternData.sheet
    const data = {
      ...formData,
      ...sheetData,
      pattern: mix_patternData
    };


    if(formData.name.length > 30) {
      enqueueSnackbar(
        t("att.timesheet.Timesheet Name can not be greater than 30 characters"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      return;
    }

    if(formData.description.length > 100) {
      enqueueSnackbar(
        t("att.timesheet.Description can not be greater than 100 characters"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      return;
    }

    const result = await TimesheetService.addTimesheet(data);
    if (result.status < 400){
      SnackbarUtil.success(t("common.Success"))
      navigate(WebURL.ATT_TIMESHEET);
    }
  };

  useEffect(() => {
    if (timeSheetId) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${timeSheetId}`).then((res) => {
        tsForm.setValue("name", res.data?.name);
        tsForm.setValue("description", res.data?.description);
        setOption(res.data.option)
        if (res.data?.description) {
          setDescriptionToolTip(res.data?.description)
        }
      })
    }
  }, []);
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <CustomBreadcrumbs title={!timeSheetId ? t("att.timesheet.New Timesheet"): t("att.timesheet.View Timesheet")} />
      </Grid>
      <Grid container={true} rowSpacing={1} sx={{margin: "0"}} component={"form"}>
        <Grid item xs={12} sx={{p: "0 16px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} >
            <Grid item={true} xs={4}>
              <ControlTextField
                label={t("att.timesheet.Timesheet Name")}
                name={"name"}
                form={tsForm}
                required
                disabled={!!timeSheetId}
                />
            </Grid>
            <Grid item={true} xs={8}>
              <Tooltip title={descriptionToolTip}>
                <ControlTextField
                  label={t("att.timesheet.Description")}
                  name={"description"}
                  form={tsForm}
                  disabled={!!timeSheetId}
                  />
              </Tooltip>
              </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TimesheetSetting ref={sheetRef} cycle={periodCycle} period={"week"} timesheet={timesheet}
                            view_data={timeSheetId} setOption={setOption}/>
        </Grid>
        <Grid item xs={12}>
          <PatternSetting ref={patternRef} pattern={pattern} view_data={timeSheetId} option={option}/>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Stack spacing={2} direction={"row"}>
            {timeSheetId ? null : (
              <Button id='SaveButton' variant={"contained"}  onClick={tsForm.handleSubmit(onSubmit)}
            >{t("common.Save")}</Button>
            )}
            <Button id='CancelButton' variant={"outlined"} onClick={() => {
              navigate(WebURL.ATT_TIMESHEET)
            }}>{t("common.Cancel")}</Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
export default NewTimesheet
