import React, {useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  useMediaQuery,
  useTheme
} from "@mui/material";
import theme from "../../theme";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18n from "../../config/i18n";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CustomTooltip from "@/components/breadcrumbs/CustomToolTip"
 
export type DialogFuncProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  helpInfo?: string;
  description?: React.ReactNode;
  content?: React.ReactNode;
  onConfirm?: () => any;
}

type ConfirmDialogProps = DialogFuncProps & {
  mode?: string;
  close: () => void;
  open: boolean
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({close, ...props}) => {
  const {
    icon,
    title,
    helpInfo,
    description,
    content,
    open,
    onConfirm
  } = props
  const currentTheme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(currentTheme.breakpoints.down('md'));
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Dialog
            open={open}
            fullScreen={fullScreen}
            className={"ZKConfirmDialog-container"}
          >
            <DialogTitle sx={{padding: '10px !important'}}>
              <Box sx={{display: "inline-block", alignItems: "center"}}>{title}</Box>
              {helpInfo && <CustomTooltip helpInfo={helpInfo} anchor={'right'}/>}
            </DialogTitle>
            {(content || description) && <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
               {description}
               {content}
            </DialogContent>}
            <DialogActions sx={{padding: '0px !important'}}>
              <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
              <Button id='ConfirmButton' onClick={() => {
                const hold = onConfirm?.();
                if(!hold){
                  close();
                }
              }} variant={"contained"}>{t("common.Confirm")}</Button>
              <Button id='CancelButton' onClick={close}>{t("common.Cancel")}</Button>
              </Box>
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  )
}
export default ConfirmDialog
