import {parse, format} from "date-fns";

const time2datetime = (time: string) => {
  return parse(time, "HH:mm", new Date());
}
const datetime2timeString = (datetime: Date|null) => {
  if(!datetime){
    return ""
  }
  return format(datetime, "HH:mm").toString();
}
const datetime2string = (datetime: Date|null, stringFormat='yyyy-MM-dd HH:mm:ss') => {
  if(!datetime){
    return ""
  }
  return format(datetime, stringFormat).toString();
}
const Util = {
  time2datetime,
  datetime2timeString,
  datetime2string
}
export default Util;
