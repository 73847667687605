import React, { useState, useEffect, useRef, Fragment } from "react";
import { GridColDef, GridValueGetterParams, GridRenderCellParams, GridFilterModel, GridFilterOperator, GridFilterItem, GridFilterInputValueProps } from "@mui/x-data-grid";
import {ATTEndpoints} from "../../../../../services/att/endpoints";
import CustomDataGrid from "../../../../../components/data-grid/CustomDataGrid";
import WebURL from "../../../../../urls";
import { Box, Dialog, DialogContent, TextField, DialogTitle, IconButton, Typography, Grid} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import InfoAction from "@/components/actions/Info";

const timecardStyle = {
  '& .MuiPaper-root': {width: '60vw', top: '-20vh'},
  '.MuiDialog-paper': {maxWidth: '100vw'},
};

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>(item.value ?? [],);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [lowerBound, upperBounding] });
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      <DatePicker
        label="start date"
        value={filterValueState[0]}
        maxDate={filterValueState[1]}
        onChange={handleLowerFilterChange}
      />
      <DatePicker
        label="end date"
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        onChange={handleUpperFilterChange}
      />
    </Box>
  )
}

const ExtraAction = () => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <InfoAction setInfo={setInfo} />
      </Grid>
      <InfoDialog info={info} setInfo={setInfo} />
    </Fragment>
  )
}

const InfoDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={timecardStyle}
    >
      <DialogTitle>
        {t("common.Usage Tips")}
        <IconButton
          id='CloseIconButton'
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
        <Box>
          <Typography>{t("att.timecard.Info_1")}</Typography>
        </Box>
        <Box sx={{margin: '15px 0'}}>
          <Typography>{t("att.timecard.Info_2")}</Typography>
        </Box>
        <Box>
          <Typography>{t("att.timecard.Info_3")}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const Timecard: React.FC = () => {
  const {t} = useTranslation();

  // const columns: GridColDef[] = [
  //   {field: 'employee_code', headerName: "Person ID", type: "string", flex: 0.2},
  //   {field: 'employee_name', headerName: "Person Name", flex: 0.2},
  //   {field: 'att_date', headerName: "Date", type: "date", flex: 0.2},
  //   {field: 'timesheet_name', headerName: "Timesheet", flex: 0.2},
  //   {field: 'clock_in', headerName: "Clock In", flex: 0.2},
  //   {field: 'clock_out', headerName: "Clock Out", flex: 0.2},
  //   {field: 'period_hrs', headerName: "Period Hrs", flex: 0.1},
  // ]
  const attDateOperators: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({ value }) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10)
    ]
  });
  const defaultColumns: GridColDef[] = [
      {field: 'employee_code', headerName: t("common.Person ID"), type: "string", flex: 0.1, width: 310},
      {field: 'employee_name', headerName: t("common.Person Name"), flex: 0.1, width: 310},
      // {field: 'att_date', headerName: "Date", type: "date", flex: 0.2},
      { field: 'att_date', headerName: t("att.report.Date"), type: "date", flex: 0.1, width: 310},
      {field: 'timesheet_name', headerName: t("att.timesheet.Timesheet"), flex: 0.1, width: 310},
    ]
  const [gridColumn, setColumns] = useState<GridColDef[]>(() => {
    return [...defaultColumns];
  });

  const PairingField = (params: GridRenderCellParams) => {
    let pairing = "";
    switch (params.value) {
      case "first_last":
        pairing = t("att.timesheet.First and Last")
        break;
      case "odd_even":
        pairing = t("att.timesheet.Odd Even")
        break;
      default:
        break;
    }
    return (
      <Grid>
        {pairing}
      </Grid>
    )
  }

  const [filterDate, setFilterDate] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: 'att_date',
        value: [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
        operatorValue: "between",
      }
    ]
  })

  const ExtendColumns = [
    { field: 'clock_in', headerName: t("common.Clock In"), filterable: false , flex: 0.1},
    { field: 'clock_out', headerName: t("common.Clock Out"), filterable: false , flex: 0.1},
    { field: 'period_hrs', headerName: t("common.Clock Time(h)"), filterable: false , flex: 0.1},
    { field: 'total', headerName: t("common.Total Clock Time(h)"), filterable: false, flex: 0.1 },
    { field: 'pairing', headerName: t("common.Statistic Rule Mode"), filterable: false ,renderCell: PairingField, flex: 0.1}
  ]

  const processResponse = (data: any) => {
    const calcColumns: GridColDef[] = []
    let maxColumnsCount = 0;
    const result: any = [];
    if (data.data.length === 0) {
      calcColumns.push(...defaultColumns, ...ExtendColumns);
    } else {
      calcColumns.push(...defaultColumns);
      data.data.forEach((element: any) => {
        // get the largest column count
        const len = element.paired.length;
        maxColumnsCount = Math.max(maxColumnsCount, len);
        // uppack column data
        let paired_objs = {}
        element.paired.forEach((paired_obj: any) => {
          paired_objs = { ...paired_objs, ...paired_obj };
        });
        console.log("paired_objs", paired_objs);
        result.push({ ...element, ...paired_objs })
      });
      // add dynamic column
      if (maxColumnsCount) {
        for (let i = 0; i < maxColumnsCount; i++) {
          calcColumns.push({ field: `clock_in${i}`, headerName: t("common.Clock In"), filterable: false , flex: 0.1})
          calcColumns.push({ field: `clock_out${i}`, headerName: t("common.Clock Out"), filterable: false , flex: 0.1})
          calcColumns.push({ field: `period_hrs${i}`, headerName: t("common.Clock Time(h)"), filterable: false , flex: 0.1})
        }
        calcColumns.push({
          field: 'total',
          headerName: t("common.Total Clock Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.total || "",
          filterable: false,
          flex: 0.1
        },{
          field: 'paid_break',
          headerName: t("common.Total Break Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.paid_break || "",
          filterable: false,
          flex: 0.1
        },{
          field: 'paid_work',
          headerName: t("common.Total Work Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.paid_work || "",
          filterable: false,
          flex: 0.1
        })
        calcColumns.push({
          field: 'pairing',
          headerName: t("common.Statistic Rule Mode"),
          renderCell: PairingField,
          filterable: false,
          flex: 0.1
        });
      } else {
        calcColumns.push(...ExtendColumns);
      }
    }
    setColumns(calcColumns);
    return { data: result, total: data.total };
  }

  return (
    <>
      <CustomDataGrid
        uri={ATTEndpoints.ATT_TIMECARD_URI}
        columns={gridColumn}
        checkboxSelection={false}
        localeText={{
          noRowsLabel: t("att.timecard.No timecard found"),
          noResultsOverlayLabel: t("att.timecard.No timecard found")
        }}
        // filterModel={filterDate}
        // requestParams={filterParams}
        processResponse={processResponse}
        onFilterModelChange={(model) => {
        if (typeof(model.items[0].value[0]) == 'object') {
          model.items[0].value[0] = model.items[0].value[0].toISOString().slice(0, 10)
        }
        if (typeof(model.items[0].value[1]) == 'object') {
          model.items[0].value[1] = model.items[0].value[1].toISOString().slice(0, 10)
        }
        setFilterDate(model)
        setFilterParams({date_range: [
          model.items[0].value[0],
          model.items[0].value[1]
        ]})
      }}
        toolbar={{
          title: t("att.timecard.Timecard"),
          search: true,
          searchPlaceholder: t("att.report.Search by Person, TimeSheet"),
          breadcrumbs: [
            {breadcrumbName: "", path: WebURL.ATT_TIMECARD_HOME},
            {breadcrumbName: t("att.timecard.Timecard"), path: WebURL.ATT_TIMECARD_TIMECARD}
          ],
          // children: <ExtraAction />  // Tip content needs to be corrected
        }}
      />
    </>
  )
}
export default Timecard
