import { Box, Button, Grid, Breadcrumbs, Typography, Badge, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import GenericService from "@/services/GenericService";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ATTEndpoints } from "@/services/att/endpoints";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Refresh, RemoveRedEyeOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

import TableContainer from '@mui/material/TableContainer';
import {useTranslation} from "react-i18next";


type GridData = {
  data: any;
  total: number;
  num_pages: number;
}

type EmpAttProp = {
  setWaiting: Dispatch<SetStateAction<boolean>>
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 125,
  lineHeight: '60px',
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const EmployeeAttendance: React.FC<EmpAttProp> = (props) => {
  const {t} = useTranslation();
  const [gridData, setGridData] = useState<GridData>({ data: [], total: 0, num_pages: 0 });
  const [pageSize, setPageSize] = useState<number>(16);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const refreshGridData = () => {
    setPage(0)
    setRefresh(refresh + 1);
  };

  const handleScroll = (event: any) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position < 1) {
      console.log(listboxNode.scrollHeight, position)
      appendGridData()
    }
  };

  const fetchGridData = () => {
    setLoading(true)
    GenericService.list(ATTEndpoints.ATT_EMPLOYEE_PRESENT_STATUS_URI, { current: (page + 1), pageSize: pageSize }).then((response) => {
      setGridData(response.data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
      props.setWaiting(true)
    });
  }

  const appendGridData = () => {
    // setLoading(true)
    const newPage = page + 1
    if (newPage >= gridData.num_pages) {
      return
    }

    GenericService.list(ATTEndpoints.ATT_EMPLOYEE_PRESENT_STATUS_URI, { current: (newPage + 1), pageSize: pageSize }).then((response) => {
      setPage(newPage);
      response.data.data = gridData.data.concat(response.data.data)
      setGridData(response.data);
    }).catch((error) => {
      // setPage(page - 1);
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      // setLoading(false);
    });
  }

  useEffect(() => {
    fetchGridData()
  }, [refresh]);

  return (
    <Box sx={{ width: "100%", height: "550px" }}>
      <Grid sx={{ paddingBottom: "5px" }} className={"toolbar-container"}>
        <Box sx={{ display: 'flex', }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
            <Typography>{t("common.Attendance status")}</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid sx={{ marginLeft: "8px" }} item={true}>
              <Tooltip title={t("att.report.Refresh")}>
                <IconButton id='RefreshButton' onClick={refreshGridData} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <TableContainer onScroll={handleScroll} sx={{ backgroundColor: "transparent", height: "100%" }} component={Box}>
        <CircularProgress sx={{
          position: "relative",
          left: "50%",
          top: "50%",
          marginTop: "-25px",
          marginLeft: "-25px",
          display: isLoading ? "block" : "none",
        }} />
        <Grid>
          <Box
            sx={{
              gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))',
              gap: 2,
              padding: "8px 0 8px 0",
              display: isLoading ? "none" : "grid",
            }}
          >
            {gridData?.data.map((item: any, index: any) => (
              <Item
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  position: "relative",
                }}
                key={index} elevation={0}
              >
                <Grid item sx={{ padding: "20px", paddingTop: "5px" }}>
                  <Badge
                    color="success"
                    badgeContent=" "
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{
                      '.MuiBadge-badge': { backgroundColor: item.att_status === 'Absent' ? "#ef5656" : "#20cf61", bottom: "10px", right: "10px", border: `4px solid #fff` }
                    }}
                  >
                    <AvatarIcon diameter={50} name={item.first_name ? item.first_name : "None"}
                        profilePhoto={item?.profilePhotoURL}
                        style={{ padding: "10px" }}
                    />
                  </Badge>
                </Grid>
                <Grid item sx={{ maxWidth: 'calc(100% - 120px)' }}>
                  <Tooltip title={item.first_name ? item.first_name : ''}>
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>{item.first_name}</Typography>
                  </Tooltip>
                  <Tooltip title={item.employee_code ? item.employee_code : ''}>
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>{item.employee_code}</Typography>
                  </Tooltip>
                  <Tooltip title={item.department_name ? item.department_name : ''}>
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>{item.department_name}</Typography>
                  </Tooltip>
                  <Tooltip title={item.designation_name ? item.designation_name : ''}>
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>{item.designation_name}</Typography>
                  </Tooltip>
                </Grid>
                {/* <Grid sx={{ position: "absolute", right: "10px" }}>
                  <Typography>
                    <IconButton sx={{ borderRadius: '6px' }}>
                      <ShareOutlinedIcon />
                    </IconButton>
                  </Typography>
                  <Typography>
                    <IconButton sx={{ borderRadius: '6px' }}>
                      <ChatOutlinedIcon />
                    </IconButton>
                  </Typography>
                  <Typography>
                    <IconButton sx={{ borderRadius: '6px' }}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                  </Typography>
                </Grid> */}
              </Item>
            ))}
          </Box>
        </Grid>
      </TableContainer>
    </Box >
  )

}

export default EmployeeAttendance;
