import React from "react";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export type BreadcrumbsProps = {
  title: string;
}

const CustomBreadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex' }}>
      <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
        <IconButton id="BackIconButton" sx={{padding: "5px"}} onClick={() => navigate(-1)}>
          <Tooltip title={t('common.Back')}>
            <ArrowBackIosNewIcon sx={{ display: "flex" }} fontSize="small" />
          </Tooltip>
        </IconButton>
        <Typography>{props.title}</Typography>
      </Breadcrumbs>
    </Box>
  )
}
export default CustomBreadcrumbs
