import { ForwardedRef, forwardRef } from "react"
import { DataGridProvider } from "./DataGridContext"
import OriginDataGrid from "./OriginDataGrid"
import { DataGridProps } from "@mui/x-data-grid";
import { ToolBarProps } from "./CustomToolBar";

const defaultProps = {
  processResponse: (data: any) => data
}

export type DataGridRef = {
  refresh: (resetPage?: boolean) => void
}

export type CustomDataGridProp = Omit<DataGridProps, 'rows'> & {
  title?: string;
  uri: string;
  authedAction?: string;
  toolbar?: ToolBarProps,
  requestParams?: Record<any, any>
  onDataChange?: (value?: any) => void;
} & Partial<typeof defaultProps>

const CustomDataGrid = (props: CustomDataGridProp, ref: ForwardedRef<DataGridRef|undefined>) => {
  return (
    <DataGridProvider>
      <OriginDataGrid {...props} ref={ref} />
    </DataGridProvider>
  )
}

export default forwardRef(CustomDataGrid)
