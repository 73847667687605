import React from "react";
import WebURL from "@/urls";

import HistoryView from "@/components/history";
import {useTranslation} from "react-i18next";

const PunchRecordHistory: React.FC = () => {
  const {t} = useTranslation();

  return (
    <HistoryView
      contentType={'punch-record'}
      title={t("common.PunchRecord")}
      backUrl={WebURL.ATT_TIMECARD_PUNCH_RECORD}
    />
  )
}
export default PunchRecordHistory
