import React from "react";
import {Route, Routes} from "react-router-dom";
import UserLogin from "./pages/auth/Login";
import EmployeeDataGrid from "./pages/hr/Employee";
import OrganizationHome from "./pages/hr/index";
import OrganizationSelection from "./pages/auth/Login/components/OrganizationSelection";
import TimesheetDataGrid from "./pages/att/Timesheet";
import TimesheetHistory from "./pages/att/Timesheet/component/History";
import ScheduleDataGrid from "./pages/att/Schedule";
import MainLayout from "./parts/MainLayout";
import Dashboard from "./pages/dashboard";
import ReportHome from "./pages/att/Report";
import FirstAndLast from "./pages/att/Report/components/FirstAndLast";
import FirstInLastOut from "./pages/att/Report/components/FirstInLastOut";
import MonthlyReport from "./pages/att/Report/components/MonthlyReport";
import DeviceGridData from "./pages/dms/Device";
import TimecardHome from "./pages/att/Timecard";
import Timecard from "./pages/att/Timecard/components/Timecard";
import CalcTaskLog from "./pages/att/Timecard/components/CalcTaskLog";
import CalcTask from "./pages/att/Timecard/components/CalcTask";

import PunchRecordGrid from "./pages/att/Timecard/components/PunchRecord";
import PunchRecordHistory from "./pages/att/Timecard/components/PunchRecord/components/History";
import NewTimesheet from "./pages/att/Timesheet/component/NewTimesheet";
import WebURL from "./urls";
import NewSchedule from "./pages/att/Schedule/components/NewSchedule";
import Schedule from "./pages/att/Schedule/components/NewSchedule/Schedule";

import ScheduleHistory from "./pages/att/Schedule/components/History";
import Zlink from "./pages/auth/Zlink";
import EmployeeView from "./pages/hr/Employee/components/EmployeeView";
import DeviceView from "./pages/dms/Device/components/DeviceView"
import DeviceHistory from "./pages/dms/Device/components/History"
import SignUp from "@/pages/auth/Login/components/SignUp";
import OrganCreation from "@/pages/auth/Login/components/OrganCreation";
import AttRuleHome from "./pages/att/RuleSetting/index"
import DeviceListGridData from "./pages/att/RuleSetting/components/ClockingInDevice";
import AddPerson from "./pages/att/RuleSetting/components/ClockingInDevice/components/AddPerson"
import PersonInDevice from "./pages/att/RuleSetting/components/ClockingInDevice/components/PersonInDevice"
import CollectionRuleList from "./pages/att/RuleSetting/components/CollectionRule";
import ViewCollectionRule from "./pages/att/RuleSetting/components/CollectionRule/ViewCollectionRule";
import DeviceAdd from "@/pages/dms/Device/components/DeviceAdd";
import LocationHome from "@/pages/location";
import SiteGird from "@/pages/location/Site";
import SiteView from "@/pages/location/Site/components/SiteView";
import AddSite from "@/pages/location/Site/components/AddSite";
import EditSite from "@/pages/location/Site/components/EditSite";
import ZoneGird from "@/pages/location/Zone";
import ZoneView from "@/pages/location/Zone/components/ZoneView";
import DeviceEdit from "@/pages/dms/Device/components/DeviceEdit";
import Role from "./pages/auth/Role";
import RoleEdit from "./pages/auth/Role/components/RoleEdit";
import SubscriptionHome from "@/pages/auth/Subscription/index"
import OrderReport from "@/pages/auth/Subscription/order/OrderReport"
import NewSubscription from "@/pages/auth/Subscription/Add"
import SubscriptionPayment from "@/pages/auth/Subscription/payment/index"
import SessionOut from "./pages/auth/Zlink/SessionOut";
import PageNotFound from "./pages/others/404";
import NoPermission from "./pages/others/NoPermission";

const MenuRoutes = () => {
  const redirectFrom = localStorage.getItem('redirectFrom');
  return (
    <Routes>
      {(process.env.REACT_APP_ZLINK_HOST || redirectFrom) ?
        <Route path={WebURL.ZKLINK_REDIRECT} element={<SessionOut />} />
        : <>
            <Route path={WebURL.AUTH_USER_LOGIN} element={<UserLogin/>}/>
            <Route path={WebURL.AUTH_SIGN_UP} element={<SignUp/>}/>
            <Route path={WebURL.AUTH_USER_COMPANY_SELECTION} element={<OrganizationSelection/>}/>
            <Route path={WebURL.AUTH_USER_COMPANY_CREATION} element={<OrganCreation/>}/>
          </>
      }
      <Route path={WebURL.ZKLINK_FORWARD} element={<Zlink/>}/>
      <Route path={"/"} element={<MainLayout/>}>
        <Route path={WebURL.DASHBOARD} element={<Dashboard/>}/>
        <Route path={WebURL.AUTH_ROLE} element={<Role/>} />
        <Route path={WebURL.AUTH_ROLE_EDIT} element={<RoleEdit/>}/>

        <Route path={WebURL.ORGANIZATION_HOME} element={<OrganizationHome/>}/>
        <Route path={WebURL.HR_EMPLOYEE} element={<EmployeeDataGrid/>}/>
        <Route path={WebURL.HR_EMPLOYEE_VIEW} element={<EmployeeView/>}/>

        <Route path={WebURL.ATT_RULE_HOME} element={<AttRuleHome/>}/>
        <Route path={WebURL.ATT_TIMESHEET} element={<TimesheetDataGrid/>}/>
        <Route path={WebURL.ATT_TIMESHEET_HISTORY} element={<TimesheetHistory/>}/>
        <Route path={WebURL.ATT_TIMESHEET_NEW} element={<NewTimesheet/>}/>
        <Route path={WebURL.ATT_TIMESHEET_VIEW} element={<NewTimesheet/>}/>
        <Route path={WebURL.ATT_CLOCKING_COLLECTION_RULE} element={<CollectionRuleList/>}/>
        <Route path={WebURL.ATT_CLOCKING_COLLECTION_RULE_VIEW} element={<ViewCollectionRule/>}/>
        {/*<Route path={WebURL.ATT_DEVICE_LIST} element={<DeviceListGridData/>}/>*/}
        <Route path={WebURL.ATT_DEVICE_PERSONS} element={<PersonInDevice/>}/>
        <Route path={WebURL.ATT_DEVICE_PERSONS_ADD} element={<AddPerson/>}/>

        <Route path={WebURL.ATT_SCHEDULE} element={<ScheduleDataGrid/>}/>
        <Route path={WebURL.ATT_SCHEDULE_HISTORY} element={<ScheduleHistory/>}/>
         {/*<Route path={WebURL.ATT_SCHEDULE_NEW} element={<NewSchedule/>}/> */}
        <Route path={WebURL.ATT_SCHEDULE_NEW} element={<Schedule/>}/>

        <Route path={WebURL.ATT_TIMECARD_HOME} element={<TimecardHome/>}/>
        <Route path={WebURL.ATT_TIMECARD_PUNCH_RECORD} element={<PunchRecordGrid/>}/>
        <Route path={WebURL.ATT_TIMECARD_PUNCH_RECORD_HISTORY} element={<PunchRecordHistory/>}/>
        <Route path={WebURL.ATT_TIMECARD_TIMECARD} element={<Timecard/>}/>

        <Route path={WebURL.ATT_CALCTASK_LOG} element={<CalcTaskLog/>}/>
        <Route path={WebURL.ATT_CALCTASK} element={<CalcTask/>}/>

        <Route path={WebURL.ATT_REPORT_HOME} element={<ReportHome/>}/>
        <Route path={WebURL.ATT_REPORT_FIRST_LAST} element={<FirstAndLast/>}/>
        <Route path={WebURL.ATT_REPORT_FILO} element={<FirstInLastOut/>}/>
        <Route path={WebURL.ATT_REPORT_MONTHLY_REPORT} element={<MonthlyReport/>}/>

        <Route path={WebURL.LOCATION_HOME} element={<LocationHome/>}/>
        <Route path={WebURL.LOCATION_SITE} element={<SiteGird/>}/>
        <Route path={WebURL.LOCATION_SITE_VIEW} element={<SiteView/>}/>
        <Route path={WebURL.LOCATION_SITE_NEW} element={<AddSite/>}/>
        <Route path={WebURL.LOCATION_SITE_EDIT} element={<EditSite/>}/>
        <Route path={WebURL.LOCATION_ZONE} element={<ZoneGird/>}/>
        <Route path={WebURL.LOCATION_ZONE_VIEW} element={<ZoneView/>}/>

        <Route path={WebURL.DMS_DEVICE} element={<DeviceGridData/>}/>
        <Route path={WebURL.DMS_DEVICE_ADD} element={<DeviceAdd/>}/>
        <Route path={WebURL.DMS_DEVICE_VIEW} element={<DeviceView/>}/>
        <Route path={WebURL.DMS_DEVICE_HISTORY} element={<DeviceHistory/>}/>
        <Route path={WebURL.DMS_DEVICE_EDIT} element={<DeviceEdit/>}/>

        <Route path={WebURL.SUBSCRIPTION_HOME} element={<SubscriptionHome/>}/>
        <Route path={WebURL.SUBSCRIPTION_ORDER} element={<OrderReport/>}/>
        <Route path={WebURL.SUBSCRIPTION_NEW} element={<NewSubscription/>}/>
        <Route path={WebURL.SUBSCRIPTION_payment} element={<SubscriptionPayment/>}/>

        {/* <Route path={WebURL.OTHER_404} element={<PageNotFound/>}/> */}
        <Route path={WebURL.OTHER_NO_PERMISSION} element={<NoPermission/>}/>
      </Route>
      <Route path="*" element={<PageNotFound/>}/>
    </Routes>
  )
}
export default MenuRoutes
