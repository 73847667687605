import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom"
import { useForm } from 'react-hook-form';
import {object, TypeOf, z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import TimesheetSetting from "../../../Timesheet/component/TimsheetSetting";
import PatternSetting, {PatternSettingFields} from "../../../Timesheet/component/PatternSetting";
import TimesheetService from "../../../../../services/att/services/TimesheetService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { AddNewTimeSheetProps } from './Sheet.Types';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ControlTextFieldTimeSheet from '@/components/hook-form/ControlTextFieldTimeSheet';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";

const DRAWER_WIDTH = 1024;

const AddNewTimeSheet: React.FC<AddNewTimeSheetProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    setOpen(props.open)
},[props.open])

  const handleClose = () => {
    props.close()
    setOpen(false)
    tsForm.reset()
};

const location = useLocation()
const [descriptionToolTip, setDescriptionToolTip] = useState<string>('');
const state = location.state as { id: string }
let timeSheetId: string | null = null
if (state) {
  timeSheetId = state.id
}

const timesheetSchema = object({
  name: z.string().min(1, t("common.This field is required!")),
  description: z.string(),
  // cycle: z.number()
  //   .min(1, "Minimum cycle is 1")
  //   .max(12, "Maximum cycle is 12"),
  // period: z.string()
})
type TimesheetInput = TypeOf<typeof timesheetSchema>;
const tsForm = useForm<TimesheetInput>({
  resolver: zodResolver(timesheetSchema),
  defaultValues: {
    name: '',
    description: ''
  }
})

const patternRef = useRef<{pattern: any}>();
const sheetRef = useRef<{sheet: any}>();
const [timesheet] = useState<any[]>([]);
const [pattern] = useState<PatternSettingFields>({max_hrs: "24", pairing: "first_last", required_record: "any",
                  day_change: "00:00", break_start: "12:00", break_end: "12:30", break_calculate_type: "auto_deducted"});
const [periodCycle] = useState<number>(1);
const [option, setOption] = React.useState('clocking');

const onSubmit = async (formData: any) => {
  const sheetData = await sheetRef.current?.sheet();
  const patternData = await patternRef.current?.pattern();
  const mix_patternData = {...sheetData, ...patternData}
  delete mix_patternData.sheet
  const data = {...formData, ...sheetData, pattern: mix_patternData };
  try {

    if(formData.name.length > 30) {
      enqueueSnackbar(
        t("att.timesheet.Timesheet Name can not be greater than 30 characters"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      return;
    }

    if(formData.description.length > 100) {
      enqueueSnackbar(
        t("att.timesheet.Description can not be greater than 100 characters"),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      return;
    }

    const result = await TimesheetService.addTimesheet(data);
    if (result.status < 400){
      props.getNewlyAddedTimeSheet(data.name);
      SnackbarUtil.success(t("common.Success"))
      tsForm.reset()
      props.close()
    }
  } catch(error) {
    console.log("error " + error)
  }
};

 return (
    <React.Fragment>
      <form>
        <Drawer anchor="right" open={open} onClose={handleClose}
          sx= {{
            '& .MuiDrawer-paper': {
              maxWidth: DRAWER_WIDTH,
            }
          }}>
            <Box>
              <Box  sx={{paddingLeft: '10px', paddingTop: "4px", }} >
                <Grid container>
                <Grid item xs={12} >
                <Grid container={true} rowSpacing={2} columnSpacing={6} sx={{ marginTop: 1}} >
                  <Grid item={true} xs={12}  md={6}>
                    <Typography  sx={{ fontSize: '20px', fontWeight: "500", color: "#304669"}}>{t("att.timesheet.Add New Timesheet")}</Typography>
                    {/* <Typography  variant='inherit' className="tooltip-css">{t("att.timesheet.Add New Timesheet")}</Typography> */}
                  </Grid>
                  <Grid item={true} xs={12}  md={6}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 20px 20px 0px' }}>
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }}/>
                    </Box>
                  </Grid>
                  </Grid>
                </Grid>
                </Grid>
              </Box>
              <Divider sx={{ paddingTop: "10px"}} />
              <Box sx={{p: '10px', display: "flex",
                      flexDirection: "column",
                      height: "720px",
                      overflow: "hidden",
                      overflowY: "scroll",}}>
              <Grid container>
                <Grid item xs={12} >
                  <Grid container={true} rowSpacing={2} columnSpacing={6} sx={{ marginTop: 1}} >
                    <Grid item={true} xs={12} md={6}>
                      <ControlTextFieldTimeSheet label={t("att.timesheet.Timesheet Name")}
                        name={"name"} form={tsForm} required disabled={!!timeSheetId}/>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <ControlTextFieldTimeSheet label={t("att.timesheet.Description")}
                          name={"description"} form={tsForm} disabled={!!timeSheetId}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2}}>
                  <TimesheetSetting ref={sheetRef} cycle={periodCycle} period={"week"} timesheet={timesheet}
                                view_data={timeSheetId} setOption={setOption}/>
                </Grid>
                <Grid item xs={12}>
                  <PatternSetting ref={patternRef} pattern={pattern} view_data={timeSheetId} option={option}/>
                </Grid>
              </Grid>
              </Box>

              {/* <Box sx={{ position: "static", bottom: 0, textAlign: "left", paddingBottom: 3, display:"block", clear:"both"}}> */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end',}}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: 3}}>
                <Stack spacing={2} direction={"row"}>
                  <Button sx={{ width: 90}} id='SaveButton' variant={"contained"} onClick={tsForm.handleSubmit(onSubmit)}
                  >{t("common.Save")}</Button>
                  <Button id='CancelButton' variant={"outlined"} onClick={handleClose}>{t("common.Cancel")}</Button>
                </Stack>
                </Grid>
              </Box>

            </Box>

        </Drawer>


      </form>
    </React.Fragment>
  )
}

export default AddNewTimeSheet

