import React, { useEffect, useRef, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import WebURL from "../../../urls";
import Switch from '@mui/material/Switch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from "@/components/dialog"
import DeviceService from '@/services/dms/DeviceService';
import SnackbarUtil from '@/utils/SnackbarUtil';
import GenericService from "@/services/GenericService";
import { DMSEndpoints } from "@/services/dms/endpoints";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {timezones} from "@/components/resource/timezone";
import {Box, Button, Grid, Stack, TextField} from "@mui/material";
import HistoryAction from "@/components/actions/History";
import FilterAction from "@/components/actions/Filter";
import { Controller, useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed";

import { makeStyles } from '@mui/styles';

const useStyles  = makeStyles({
  menuItem: {
    "&:hover": {
      color: "rgb(54, 201, 109) !important"
    }
  }
});

const StatusField = (params: GridRenderCellParams) => {
  const {t} = useTranslation()
  if (params.value === '1') {
    return (
      <strong style={{color: '#006644'}}>
        {t('dms.Online')}
      </strong>
    )
  } else {
    return (
      <strong style={{color: '#BF2600'}}>
        {t('dms.Offline')}
      </strong>
    )
  }
}

const ExtraToolBar = () => {
  return (
    <Grid item={true}>
      <HistoryAction historyUrl={WebURL.DMS_DEVICE_HISTORY} />
    </Grid>
  )
}

const EnableField = (cell: GridRenderCellParams) => {
  const {t} = useTranslation()
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      Dialog.confirm({
        title: "Are you sure!",
        content: `You want to enable this device '${cell.row.alias}'?`,
        onConfirm: () => {
          DeviceService.enableDevice(cell.row.id).then(
            (result) => {
              console.log('DeviceService.enableDevice: ', result)
              setChecked(true);
              SnackbarUtil.success(t("common.Success"))
            }
          );
        },
      });
    } else {
      Dialog.confirm({
        title: "Are you sure!",
        content: `You want to disable this device '${cell.row.alias}'?`,
        onConfirm: () => {
          DeviceService.disableDevice(cell.row.id).then(
            (result) => {
              console.log('DeviceService.disableDevice: ', result)
              setChecked(false);
              SnackbarUtil.success(t("common.Success"))
            }
          );
        },
      });
    }
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}


const DeviceActionField = (refresh: () => void) => (cell: GridRenderCellParams) => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const DMS_DEVICE_PERSON_PERMISSION_NAME = "DMS_DEVICE_PERSON"
  const isAuthed = isEligibleAction(DMS_DEVICE_PERSON_PERMISSION_NAME)

  return (
    <div>
      <IconButton
        id="MoreVertIconButton"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: "#304669" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={async () => {
          navigate(WebURL.DMS_DEVICE_VIEW, {state: {id: cell.row.id}})
        }}>{t("dms.View device details")}</MenuItem>
        <MenuItem className={classes.menuItem} onClick={async () => {
          navigate(WebURL.DMS_DEVICE_EDIT, {state: {id: cell.row.id}})
        }}>{t("dms.Edit device")}</MenuItem>
        {isAuthed && <MenuItem className={classes.menuItem} onClick={async () => {
          navigate(WebURL.ATT_DEVICE_PERSONS, {state: {id: cell.row.id}})
        }}>{t("dms.View Device Person")}</MenuItem>}
        <MenuItem className={classes.menuItem} onClick={async () => {
          setAnchorEl(null);
          if (Number(cell.row.status) === 0){ //offline
            SnackbarUtil.error(t("dms.Device cannot do this action if offline"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
            return
          }
          Dialog.confirm({
            title: t("common.Are you sure!"),
            content: t("dms.You want sync date and time for this device?", {sn: cell.row.alias}),
            onConfirm: () => {
              const tempData = {
                deviceAlias: cell.row.alias,
                siteId: cell.row.site,
                zoneId: cell.row.zone,
              }
              DeviceService.update(cell.row.id, tempData)
              DeviceService.syncDateTimeDevice(cell.row.id).then(
                (result) => {
                  console.log('DeviceService.syncDateTimeDevice: ', result)
                  SnackbarUtil.success(t("common.Success"))
                }
              );
            },
          });
        }}>
          {t("dms.Sync date & time to device")}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={async () => {
          setAnchorEl(null);
          if (Number(cell.row.status) === 0){ //offline
            SnackbarUtil.error(t("dms.Device cannot do this action if offline"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
            return
          }
          Dialog.confirm({
            title: t("common.Are you sure!"),
            content: t("dms.You want to reboot this device?", {sn: cell.row.alias}),
            onConfirm: () => {
              DeviceService.rebootDevice(cell.row.id).then(
                (result) => {
                  console.log('DeviceService.rebootDevice: ', result)
                  SnackbarUtil.success(t("common.Success"))
                }
              );
            },
          });
        }}>
          {t("dms.Reboot device")}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={async () => {
          setAnchorEl(null);
          Dialog.confirm({
            title: t("common.Are you sure!"),
            content: t("dms.You want to delete this device?", {sn: cell.row.alias}),
            onConfirm: () => {
              GenericService.destroy(DMSEndpoints.DMS_DEVICE_URI, `${cell.id}`).then(
                (result) => {
                  console.log('DeviceService.rebootDevice: ', result);
                  SnackbarUtil.success(t("common.Success"))
                  refresh();
                }
              );
            }
          });
        }}>
          {t("dms.Delete device")}
        </MenuItem>
      </Menu>
    </div>
  )
}

const DeviceGridData: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>({} as DataGridRef);
  const [refresh, setRefresh] = useState<boolean>(true);
  const timezoneList = timezones();
  const columns: GridColDef[] = [
    { field: 'alias', headerName: t('dms.Device Name'), minWidth: 130, flex: 0.2 },
    { field: 'sn', headerName: t('dms.Serial Number'), minWidth: 130, flex: 0.2 },
    { field: 'site_name', headerName: t('dms.Site Name'), flex: 0.2 },
    { field: 'timezone', headerName: t('dms.Site Time Zone'), minWidth: 210, flex: 0.3, valueGetter: (params) =>
      timezoneList.find((option:any) => option.value === params.row.timezone)?.name|| `UTC${params.row.timezone}`},
    // { field: 'ipv4', headerName: t('dms.ip'), flex: 0.2},
    { field: 'zone_name', headerName: t('dms.Zone Name'), flex: 0.2 },
    { field: 'model', headerName: t('dms.Device Model'), flex: 0.1},
    { field: 'firmware_ver', headerName: t('dms.Firmware Ver.'), flex: 0.2 },
    // { field: 'admins', headerName: "Administrator", flex: 0.1 },
    { field: 'status', renderCell: StatusField, headerName: t('dms.Status'), flex: 0.1},
    // { field: 'enable', renderCell: EnableField, headerName: "Enable/Disable", flex: 0.2 },
    {
      field: 'actions',
      type: "actions",
      headerName: t("common.Action"),
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      renderCell: DeviceActionField(() => setRefresh(!refresh))
    },
  ]

  const [filterParams, setFilterParams] = useState<Record<any, any>>({
  });
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }

  const filterFormValidate = object({
    deviceAlias: z.string().optional(),
    deviceSn: z.string().optional(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {deviceAlias: '', deviceSn: ""}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = filterForm;


  const FilterComponent = () => {
    if (!filterShow) {
      return (
        <></>
      )
    }
    const onSubmit = async (formData: any) => {
      setFilterParams(formData)
      if (formData.deviceAlias || formData.deviceSn) {
        dataGridRef.current?.refresh(true);
      }
      else {
        SnackbarUtil.error(t("dms.Enter at least 1 character please"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }
    }

    return (
      <>
      <Grid sx={{marginBottom: '5px'}} component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} direction="row">

        <Controller
          control={control}
          name="deviceAlias"
          render={({ field }) => (
            <TextField
            sx={{paddingRight: "50px", width: "400px"}}
              label={t("dms.Device Name")}
              value={field.value}
              size="small"
              error={!!errors.deviceAlias}
              helperText={errors.deviceAlias?.message || ""}
              {...register("deviceAlias")}
            />
          )}
        />
        <Controller
          control={control}
          name="deviceSn"
          render={({ field }) => (
            <TextField
            sx={{paddingRight: "50px", width: "400px"}}
              label={t("dms.Serial Number")}
              value={field.value}
              size="small"
              error={!!errors.deviceSn}
              helperText={errors.deviceSn?.message || ""}
              {...register("deviceSn")}
            />
          )}
        />

        <Button
          id='SearchButton'
          variant={"contained"}
          type='submit'
        >
          {t("common.Search")}
        </Button>
        <Button
          id='ResetButton'
          variant={"outlined"}
          onClick={() => {
            filterForm.reset()
            setFilterParams({})
            dataGridRef.current?.refresh(true);
          }}
        >
          {t("common.Reset")}
        </Button>
      </Stack>
      </Grid>
      </>
    )
  }

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [refresh])

  return (
    <CustomDataGrid
      uri={DMSEndpoints.DMS_DEVICE_URI + "?deviceType=att"}
      authedAction={"DEVICE_MANAGE"}
      columns={columns}
      requestParams={filterParams}
      ref={dataGridRef}
      checkboxSelection={false}
      localeText={{
        noRowsLabel: t("dms.No device found"),
        noResultsOverlayLabel: t("dms.No device found")
      }}
      toolbar={{
        title: t("dms.Device"),
        search: false,
        breadcrumbs: [
          { breadcrumbName: t("dms.Device"), path: WebURL.DMS_DEVICE }
        ],
        actions: [{
          key: 'add', authedAction: "DEVICE_ADD", icon: Add, helpTxt: t('common.Add'), callback: async () => {
            localStorage.setItem('step', '');
            navigate(WebURL.DMS_DEVICE_ADD);
          }
        }],
        children: <ExtraToolBar/>,
        filter: <FilterAction handleShowFilter={handleShowFilter} />,
        belowChildren: <FilterComponent  />,
      }}
    />
  )
}
export default DeviceGridData
