import React from "react";
import WebURL from "@/urls";

import HistoryView from "@/components/history";
import {useTranslation} from "react-i18next";

const TimesheetHistory: React.FC = () => {
  const {t} = useTranslation();

  return (
    <HistoryView
      contentType={'timesheet'}
      title={t("att.timesheet.Timesheet")}
      backUrl={WebURL.ATT_TIMESHEET}
    />
  )
}
export default TimesheetHistory
