import React, { useRef, useState } from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Box, Button, Grid, Stack, TextField, Tooltip} from "@mui/material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import WebURL from "../../../urls";
import {useNavigate} from "react-router-dom";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import {useTranslation} from "react-i18next";
import FilterAction from "@/components/actions/Filter";
import EmployeeFilterFC from "./components/EmployeeFilterFC"
import SnackbarUtil from "@/utils/SnackbarUtil";

const EmployeeDataGrid: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), minWidth: 220, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <AvatarIcon name={params.row.firstName} profilePhoto={params.row?.profilePhotoURL} />
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", minWidth: 120},
    {field: 'email', headerName: t("login.Email"), type: "string", minWidth: 200},
    {field: 'phone', headerName: t("common.Mobile"), type: "string", minWidth: 180},
    {field: 'department_name', headerName: t("common.Department"), type: "string", minWidth: 180},
    {field: 'designation_name', headerName: t("common.Job Title"), type: "string", minWidth: 180},
    {field: 'joinDate', headerName: t("common.Date of joining"), type: "date", minWidth: 120},
    // {field: 'roleIdentifier', headerName: "User Role"), type: "string", minWidth: 120},
    {
      field: 'actions', headerName: t("common.Action"), headerAlign: "center", type: "actions", align: "center", renderCell: (e) => {
        return (
          <Tooltip title={t('common.View')} placement="right">
            <div>
            <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
            key: "view",
            onClick: () => {
              // navigate(`${WebURL.HR_EMPLOYEE}/${e.row.id}`);
              navigate(WebURL.HR_EMPLOYEE_VIEW, {state: {id: e.row.id}});
            }
          }}/>
          </div>
          </Tooltip>
        )
      }
    },
  ]
  const dataGridRef = useRef<DataGridRef>();
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
  });
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const handleShowFilter = () => {
    setFilterShow(!filterShow)
  }

  const onSubmit = async (formData: any) => {
    setFilterParams(formData)
    if (formData.firstName || formData.lastName || formData.code) {
      dataGridRef.current?.refresh(true);
    }
    else {
      SnackbarUtil.error(t("dms.Enter at least 1 character please"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
    }
  }

  return (
    <CustomDataGrid
      ref={dataGridRef}
      uri={HREndpoints.HR_ACTIVE_EMPLOYEE_URI}
      authedAction={"PERSON_MANAGE"}
      columns={columns}
      requestParams={filterParams}
      checkboxSelection={false}
      localeText={{
        noRowsLabel: t("organ.Employee no rows"),
        noResultsOverlayLabel: t("organ.Employee no rows")
      }}
      toolbar={{
        title: t("common.Person"),
        search: false,
        filter: <FilterAction handleShowFilter={handleShowFilter} />,
        breadcrumbs: [
          {breadcrumbName: t("common.sidebar.Organization"), path: WebURL.ORGANIZATION_HOME},
          {breadcrumbName: t("common.Person"), path: WebURL.HR_EMPLOYEE}
        ],
        belowChildren: <EmployeeFilterFC show={filterShow} setParams={setFilterParams} onSubmit={onSubmit} refresh={dataGridRef.current?.refresh}/>,
      }}
    />
  )
}
export default EmployeeDataGrid
