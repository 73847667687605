import React, { useEffect, useRef } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import WebURL from "@/urls";
import Util from "@/utils/Util";
import { Tooltip } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";


const StatusField = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return (
      <span>
        Success
      </span>
    )
  } else {
    return (
      <span>
        Failure
      </span>
    )
  }
}

const DatetimeField = (params: GridRenderCellParams) => {
  return (
    <span>
      {Util.datetime2string(new Date(params.value))}
    </span>
  )
}

const LongTextField = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value}>
      <span>
      {params.value}
      </span>
    </Tooltip>

  )
}


type HistoryViewProp = {
  contentType: string;
  title: string;
  backUrl: string,
}


const HistoryView = (props: HistoryViewProp) => {
  const {contentType, title, backUrl} = props
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>({} as DataGridRef);
  const columns: GridColDef[] = [
    { field: 'user', headerName: t('common.User') , flex: 0.4},
    { field: 'op_time', renderCell: DatetimeField, headerName: t('common.Time'), type: "date" , flex: 0.4},
    // { field: 'ip_address', headerName: t('common.IP Address') },
    { field: 'action', headerName: t('common.Action'), flex: 0.6},
    // { field: 'targets', headerName: t('common.Object') },
    { field: 'action_status', renderCell: StatusField, headerName: t('common.State'), flex: 0.3},
    { field: 'description', renderCell: LongTextField, headerName: t('common.Content') },
  ]

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [])

  return (
    <CustomDataGrid
      uri={`/base/api/v1.0/admin_log/?content_type=${contentType}`}
      columns={columns}
      ref={dataGridRef}
      checkboxSelection={false}
      toolbar={{
        title: t("common.TitleHistory", {title: title}),
        breadcrumbs: [
          { breadcrumbName: "", path: backUrl },
          { breadcrumbName: t("common.TitleHistory", {title: title}), path: '' },
        ],
      }}
    />
  )
}
export default HistoryView
