import React from "react";
import WebURL from "@/urls";

import HistoryView from "@/components/history";
import {useTranslation} from "react-i18next";

const DeviceHistory: React.FC = () => {
  const {t} = useTranslation();

  return (
    <HistoryView
      contentType={'device'}
      title={t("dms.Device")}
      backUrl={WebURL.DMS_DEVICE}
    />
  )
}
export default DeviceHistory
